export const rights = (roles: RolesType) => {
  const { superUser, operator, sk, ok, osrCa, osr, department, module, employee } = roles;

  return {
    // ------------------------------ СПРАВОЧНИКИ

    canViewAndEditDictionariesForForm: osrCa || module || superUser,

    canViewDictionariesForEmployee: osr || osrCa || module || superUser,
    canEditDictionariesForEmployee: osrCa || module || superUser,

    canViewAspectGroupsDictionary: osrCa || module || superUser,

    canViewEmployeeDictionary: department || osr || osrCa || module || superUser,
    canViewOrganizationEmployees: osr || osrCa || superUser,
    canViewAllEmployees: module || superUser,
    canEditEmployeeDictionary: module || osr || osrCa || superUser,

    canViewOtherDictionaries: osr || osrCa || operator || module || superUser,
    canEditOtherDictionaries: osrCa || operator || module || superUser,

    canViewSanatoriumsDictionary: ok || sk || department || employee || superUser,
    canViewSanatoriumHistoryBlock: osrCa || operator || superUser,

    // ------------------------------ ЗАЯВКИ

    canViewAllApplications: osrCa || osr || superUser,
    canViewMyApplications: osrCa || osr || sk || ok || employee || department || superUser,
    canViewInboxApplications: osrCa || osr || operator || ok || superUser,
    canViewApplicationsInProgress: osrCa || osr || operator || ok || superUser,
    canViewProcessedApplications: osrCa || osr || operator || ok || sk || superUser,

    canViewAndEditApplication:
      employee || department || osr || osrCa || ok || sk || operator || superUser,

    canViewApplicationRejection: employee || department || osr || osrCa || operator || superUser,
    canEditApplicationRejection: employee || department || superUser,
    canAgreementApplicationRejection: osr || osrCa || superUser,

    canViewAndEditAlternativeApplication: employee || department || osrCa || operator || superUser,

    canCreateApplicationForOtherEmployee: department || osr || osrCa || superUser,

    canDownloadApplications: osr || osrCa || superUser,

    // ------------------------------ РЕЕСТР ЗАЯВОК

    canViewAndEditCreateApplicationRegistry: sk || operator || osr || osrCa || superUser,

    canViewApplicationRegistry: osr || osrCa || sk || operator || superUser,
    canEditApplicationRegistry: osr || osrCa || superUser,
    canSendApplicationRegistryToSk: osr || osrCa || superUser,
    canDeleteApplicationRegistry: osr || osrCa || superUser,

    canApproveApplicationRegistry: sk || superUser,
    canDownloadApplicationRegistry: osr || osrCa || sk || operator || superUser,
    canDownloadIpr: osr || superUser,

    canViewAllApplicationRegistries: sk || osrCa || superUser,

    canMakeDecisionOfCommissionOnApplication: sk || superUser,

    // ------------------------------ РЕЕСТР ПУТЁВОК

    canViewTravelPackageRegistry: osr || osrCa || superUser,

    // ------------------------------ АНКЕТА ОБРАТНОЙ СВЯЗИ ПО ОРГАНИЗАЦИИ СКЛ И РОМ

    // canViewFeedback: employee || department || osr || osrCa || superUser,
    // canEditFeedback: employee || department || superUser,
    canViewFeedback: superUser,
    canEditFeedback: superUser,

    // ------------------------------ АДМИНИСТРИРОВАНИЕ

    canViewAdministration: osr || osrCa || module || superUser,

    // ------------------------------ РЕДАКТИРОВАНИЕ ФОРМУЛЫ РАСЧЁТА ПУТЁВКИ

    canViewAndEditPrice: osrCa || module || superUser,

    // ------------------------------ РЕДАКТИРОВАНИЕ КРАТНОСТИ ПРЕДОСТАВЛЕНИЯ ПУТЕВКИ

    canViewAndEditFrequency: osrCa || module || superUser,

    // ------------------------------ ОТЧЁТНОСТЬ

    canViewReporting: osr || osrCa || superUser,

    // ------------------------------ ПОДБОР/ПРОСМОТР САНАТОРИЯ

    canViewAndEditSanatoriums: employee || department || osr || osrCa || superUser,

    // ------------------------------ САНАТОРИЙ

    canViewAndEditSanatorium: module || superUser,
    canEditSanatoriumRating: osrCa || superUser,

    // ------------------------------ УВЕДОМЛЕНИЯ

    canViewNotifications:
      operator || sk || ok || osrCa || osr || department || employee || superUser,

    // ------------------------------ ПРОФИЛЬ

    canViewProfile: employee || department || osr || osrCa || superUser || sk || ok,
    canEditProfile: employee || department || osr || osrCa || superUser || sk || ok,

    // ------------------------------ САНАТОРИИ

    canViewSanatoriumList: ok || sk || employee || department || osr || osrCa || superUser,
    canChooseSanatorium: superUser || sk || ok || osrCa || osr || department || module || employee,
  };
};

export type RolesType = {
  superUser: boolean;
  operator: boolean;
  sk: boolean;
  ok: boolean;
  osrCa: boolean;
  osr: boolean;
  department: boolean;
  module: boolean;
  employee: boolean;
};

export type RoleModelType = RolesType & {
  canViewSanatoriumList: boolean;
  canViewSanatoriumsDictionary: boolean;
  canViewSanatoriumHistoryBlock: boolean;
  canViewAndEditDictionariesForForm: boolean;
  canViewDictionariesForEmployee: boolean;
  canEditDictionariesForEmployee: boolean;
  canViewAspectGroupsDictionary: boolean;
  canViewEmployeeDictionary: boolean;
  canViewOrganizationEmployees: boolean;
  canViewAllEmployees: boolean;
  canEditEmployeeDictionary: boolean;
  canViewOtherDictionaries: boolean;
  canEditOtherDictionaries: boolean;
  canViewAndEditApplication: boolean;
  canViewApplicationRejection: boolean;
  canEditApplicationRejection: boolean;
  canAgreementApplicationRejection: boolean;
  canViewAndEditAlternativeApplication: boolean;
  canCreateApplicationForOtherEmployee: boolean;
  canDownloadApplications: boolean;
  canViewAndEditCreateApplicationRegistry: boolean;
  canDownloadApplicationRegistry: boolean;
  canDownloadIpr: boolean;
  canViewApplicationRegistry: boolean;
  canEditApplicationRegistry: boolean;
  canDeleteApplicationRegistry: boolean;
  canSendApplicationRegistryToSk: boolean;
  canApproveApplicationRegistry: boolean;
  canViewAllApplicationRegistries: boolean;
  canMakeDecisionOfCommissionOnApplication: boolean;
  canViewTravelPackageRegistry: boolean;
  canViewFeedback: boolean;
  canEditFeedback: boolean;
  canViewAdministration: boolean;
  canViewAndEditPrice: boolean;
  canViewAndEditFrequency: boolean;
  canViewReporting: boolean;
  canViewAndEditSanatoriums: boolean;
  canViewAndEditSanatorium: boolean;
  canEditSanatoriumRating: boolean;
  canViewNotifications: boolean;
  canViewProfile: boolean;
  canEditProfile: boolean;
  canViewAllApplications: boolean;
  canViewMyApplications: boolean;
  canViewInboxApplications: boolean;
  canViewApplicationsInProgress: boolean;
  canViewProcessedApplications: boolean;
  canChooseSanatorium: boolean;
};
