import React, { FC } from "react";
import { SectionsType } from "../../../constants/sections";
import { FolderButton } from "./FolderButton";
import { SectionButton } from "./SectionButton";

export const SectionButtons: FC<PropsType> = (props) => {
  const { sections } = props;

  return (
    <>
      {sections.map((section, i) => {
        const { name, type } = section;

        if (type === "section") {
          return <SectionButton key={i} section={section} />;
        } else if (type === "folder") {
          const subsections =
            sections.filter(({ type, folder }) => type === "subsection" && folder === name) ?? [];

          return <FolderButton key={i} section={section} subSections={subsections} />;
        }
      })}
    </>
  );
};

type PropsType = {
  sections: SectionsType;
};
