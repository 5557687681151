import { hrApi } from "./api";
import { LittleOtherType, OtherWithIconType, WorkingConditionsClassType } from "./dictionaries";
import { entitiesBase } from "./request";
import { IdType } from "./types";

// ------------------------------ РАСЧЁТ СТОИМОСТИ ПУТЁВКИ

export const calculationSettingsApi = <CS>() =>
  entitiesBase<CalculationSettingsType, CS, undefined>(hrApi, "resort_CalculatePriceSettings");

// ------------------------------ ТИПЫ - РАСЧЁТ СТОИМОСТИ ПУТЁВКИ

// ----- получаемые данные

export type CalculationSettingsType = IdType & {
  organization: LittleOtherType & { code: string };
  normativePrice: string;
  discount: string;
};

// ------------------------------ КРАТНОСТЬ ПРЕДОСТАВЛЕНИЯ ПУТЕВКИ

export const frequencySettingsApi = <FS>() =>
  entitiesBase<FrequencySettingsType, FS, undefined>(
    hrApi,
    "resort_VouchersFrequencyCalculationRule"
  );

// ------------------------------ ТИПЫ - КРАТНОСТЬ ПРЕДОСТАВЛЕНИЯ ПУТЕВКИ

// ----- получаемые данные

export type FrequencySettingsType = IdType & {
  experienceFrom: number;
  experienceUpTo?: number;
  frequency: number;
  normalWorkingConditions: boolean;
  factor: OtherWithIconType | null;
  conditionClass: WorkingConditionsClassType | null;
};
