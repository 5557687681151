import styled from "@emotion/styled";
import { Autocomplete, AutocompleteProps, ChipTypeMap } from "@mui/material";
import { ElementType } from "react";
import * as styles from "../../styles";

const { borders, colors, flex, grid, margins, size } = styles;
const { overflowOneLine, overflowLines, shadow, text } = styles;

const { white, redBackground, grayscaleLabel, grayscaleHoverBackground } = colors;
const { grayscaleInput, grayscaleOffWhite, grayscaleBeautifulBlack, grayscaleLine } = colors;

export const Wrapper = styled.div``;

export const Container = styled.div`
  position: relative;
`;

export const AutocompleteItem = styled(Autocomplete)<AutocompleteItemPropsType>`
  ${size({ h: 56, w: "100%" })};
  ${borders({ radius: 16, none: true })};
  ${({ haslabel }) => margins({ p: haslabel ? "8 16" : "16" })};

  background: ${({ color, haserror }) => (haserror ? redBackground : color)};
  cursor: pointer;

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
  }

  .MuiOutlinedInput-root {
    ${({ haslabel }) => margins({ m: haslabel ? "16 0 0" : "0", p: "0 0 8" })};
  }

  .MuiButtonBase-root {
    ${size({ s: 40 })};

    position: absolute;
    top: ${({ haslabel }) => (haslabel ? "-16px" : "-8px")};
    right: -8px;

    :hover {
      background: ${grayscaleHoverBackground};

      & > svg > path {
        fill: ${grayscaleLabel};
      }
    }
  }

  .MuiAutocomplete-popupIndicator {
    display: ${({ hiddenpopupbutton }) => (hiddenpopupbutton ? "none" : "inline-flex")};
  }

  .MuiAutocomplete-clearIndicator {
    visibility: visible;
    display: inline-flex;
    top: ${({ haslabel }) => (haslabel ? "-16px" : "-8px")};
  }

  .MuiOutlinedInput-root {
    ${margins({ p: "0 39 0 0", needImportant: true })};
  }

  .MuiChip-root {
    display: none;
  }

  input {
    ${margins({ p: "0", needImportant: true })};

    &::placeholder {
      ${({ inputcolor }) => text({ size: 15, height: 19, weight: 400, color: inputcolor })};

      opacity: 1;
    }

    z-index: 1;
  }

  label {
    ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};
    ${margins({ m: "0" })};

    position: absolute;
    left: 0;
    top: 0;
    transform: none;

    &.Mui-focused {
      ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};
    }
  }
` as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap["defaultComponent"]
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> &
    AutocompleteItemPropsType
) => JSX.Element;

export const MenuItemWrapper = styled.div`
  ${grid({ gap: 8, itemsInCenter: true, autoFlowColumn: true })};
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400 })};
`;

export const PaperProps = (size: string, isCategoryList: boolean) =>
  ({
    sx: {
      maxHeight: 264,
      width: size === "small" ? 372 : 472,
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "hidden",
      ml: -2,
      padding: 1,

      ul: {
        maxHeight: "inherit",
        width: "100%",
        overflowY: isCategoryList ? "visible" : "auto",
        padding: 0,

        li: {
          height: isCategoryList ? "auto" : 56,
          borderRadius: 2,
          marginBottom: 1,
          px: isCategoryList ? 0 : 2,
          py: 0,

          "&:hover": {
            background: isCategoryList ? white : grayscaleHoverBackground,
          },

          "&:last-child": { marginBottom: 0 },
        },

        "::-webkit-scrollbar": {
          width: 8,
        },

        "::-webkit-scrollbar-thumb": {
          background: grayscaleLine,
          borderRadius: "99em",
        },
      },
    },
  } as const);

export const SelectedOptions = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ p: "8 0 0" })};
`;

export const Item = styled.div`
  ${borders({ color: grayscaleInput, radius: 16 })};
  ${margins({ p: "12 20" })};

  background: ${grayscaleOffWhite};
`;

export const ButtonContainer = styled.div`
  ${margins({ p: "0 0 0 8" })};
`;

export const Label = styled.span`
  ${size({ w: "100%" })};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleBeautifulBlack, align: "start" })};
`;

export const Title = styled.h5`
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ m: "0 0 8 0" })};
`;

export const Description = styled.span`
  ${overflowLines(3)};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleLabel })};
  ${margins({ p: "10 0 0" })};
`;

export const Header = styled.div`
  ${flex({ vertical: "center" })};
`;

type AutocompleteItemPropsType = {
  hiddenpopupbutton?: number;
  haslabel: number;
  haserror: number;
  color: string;
  inputcolor: string;
};
