import { AxiosResponse } from "axios";
import { EntitiesResponseType } from "../api";

// ------------------------------ ДАННЫЕ

export const fetchAll = async <T>(getAll: GetAllItemsType<T>) => (await getAll()).data.items;

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

export const fetchTotalCount = async <T>(getAll: GetAllTotalCountType<T>) =>
  (await getAll()).data.totalCount;

// ------------------------------ ДАННЫЕ ПО ID

export const fetchById = async <T>(get: FetchGetPropsType<T>) => (await get()).data;

// ------------------------------ ТИПЫ

export type GetAllItemsType<T> = () => Promise<
  AxiosResponse<Pick<EntitiesResponseType<T>, "items">>
>;

type GetAllTotalCountType<T> = () => Promise<
  AxiosResponse<Pick<EntitiesResponseType<T>, "totalCount">>
>;

type FetchGetPropsType<T> = () => Promise<AxiosResponse<T>>;
