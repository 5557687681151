// ------------------------------ СПРАВОЧНИКИ

export const DICTIONARY = "/dictionaries";

export const DICTIONARY_EMPLOYEES = `${DICTIONARY}/employees`;
export const DICTIONARY_COUNTRIES = `${DICTIONARY}/countries`;
export const DICTIONARY_REGIONS = `${DICTIONARY}/regions`;
export const DICTIONARY_TREATMENT_PROFILE = `${DICTIONARY}/treatment-profiles`;
export const DICTIONARY_TREATMENT_METHODS = `${DICTIONARY}/treatment-methods`;
export const DICTIONARY_SANATORIUM_SERVICES = `${DICTIONARY}/sanatorium-services`;
export const DICTIONARY_WORKING_CONDITIONS = `${DICTIONARY}/working-conditions-classes`;
export const DICTIONARY_OCCUPATIONAL_FACTORS = `${DICTIONARY}/occupational-factors`;
export const DICTIONARY_TYPICAL_MEDICAL_PROGRAMS = `${DICTIONARY}/typical-medical-programs`;
export const DICTIONARY_SANATORIUM_ASPECTS = `${DICTIONARY}/sanatorium-aspects`;
export const DICTIONARY_ASPECT_GROUPS = `${DICTIONARY}/aspect-groups`;
export const DICTIONARY_IMPROVE_SERVICES = `${DICTIONARY}/improve-services`;
export const DICTIONARY_PATIENT_OUTCOMES = `${DICTIONARY}/patient-outcomes`;
export const DICTIONARY_STATUSES = `${DICTIONARY}/statuses`;
export const DICTIONARY_DOCUMENT_TYPES = `${DICTIONARY}/document-types`;
export const DICTIONARY_WHY_NOT = `${DICTIONARY}/why-not`;
export const DICTIONARY_REHAB_ACTIVITY = `${DICTIONARY}/rehab-activity`;

// ------------------------------ СПРАВОЧНИК САНАТОРИЕВ

export const DICTIONARY_SANATORIUMS = `${DICTIONARY}/sanatoriums`;
const DICTIONARY_SANATORIUM = (id: string | undefined) =>
  `${DICTIONARY_SANATORIUMS}/sanatorium/${id ?? ":id"}`;
export const DICTIONARY_SANATORIUM_FORM = (id: string | undefined) =>
  `${DICTIONARY_SANATORIUM(id)}/form`;
export const DICTIONARY_SANATORIUM_PREVIEW = (id: string | undefined) =>
  `${DICTIONARY_SANATORIUM(id)}/preview`;
export const DICTIONARY_SANATORIUM_HISTORY = (id: string | undefined) =>
  `${DICTIONARY_SANATORIUM(id)}/history`;

export const DICTIONARY_SANATORIUM_ROOMS = (sanatoriumId: string | undefined) =>
  `${DICTIONARY_SANATORIUMS}/${sanatoriumId ?? ":sanatoriumId"}/rooms`;
const DICTIONARY_SANATORIUM_ROOM = (sanatoriumId: string | undefined, id: string | undefined) =>
  `${DICTIONARY_SANATORIUM_ROOMS(sanatoriumId)}/room/${id ?? ":id"}`;
export const DICTIONARY_SANATORIUM_ROOM_FORM = (
  sanatoriumId: string | undefined,
  id: string | undefined
) => `${DICTIONARY_SANATORIUM_ROOM(sanatoriumId, id)}/form`;
export const DICTIONARY_SANATORIUM_ROOM_PREVIEW = (
  sanatoriumId: string | undefined,
  id: string | undefined
) => `${DICTIONARY_SANATORIUM_ROOM(sanatoriumId, id)}/preview`;
export const DICTIONARY_SANATORIUM_ROOM_HISTORY = (
  sanatoriumId: string | undefined,
  id: string | undefined
) => `${DICTIONARY_SANATORIUM_ROOM(sanatoriumId, id)}/history`;

// ------------------------------ САНАТОРИИ

export const SANATORIUMS = "/sanatoriums";
export const SANATORIUMS_ALTERNATIVE = `${SANATORIUMS}?isAlternative=true`;

export const SANATORIUM_ROOMS = (
  sanatoriumId: string | undefined,
  type?: AlternativeTypeType,
  basedRoomId?: string,
  once?: boolean
) =>
  `${SANATORIUMS}/${sanatoriumId ?? ":sanatoriumId"}/rooms${type ? `?type=${type}` : ""}${
    basedRoomId ? `${type ? "&" : "?"}id=${basedRoomId}` : ""
  }${once ? `${type || basedRoomId ? "&" : "?"}once=${once}` : ""}`;

export type AlternativeTypeType = "filters" | "rooms";

// ------------------------------ ЗАЯВКИ

export const APPLICATIONS = "/applications";

export const APPLICATIONS_APPLICATION = (id: string | undefined) =>
  `${APPLICATIONS}/application/${id ?? ":id"}`;

export const APPLICATIONS_APPLICATION_FORM = (
  id?: string,
  sanatoriumId?: string,
  roomIds?: string,
  isAlternativeData?: boolean
) =>
  `${APPLICATIONS_APPLICATION(id)}/sanatorium/${sanatoriumId ?? ":sanatoriumId"}/room/${
    roomIds ?? ":roomIds"
  }/form${isAlternativeData ? "?isAlternativeData=true" : ""}`;

export const APPLICATIONS_ALL = `${APPLICATIONS}/all`;
export const APPLICATIONS_MY = `${APPLICATIONS}/my`;
export const APPLICATIONS_INBOX = `${APPLICATIONS}/inbox`;
export const APPLICATIONS_IN_PROCESS = `${APPLICATIONS}/in-progress`;
export const APPLICATIONS_PROCESSED = `${APPLICATIONS}/processed`;

// ------------------------------ РЕЕСТРЫ

export const REGISTRIES = `/registries`;

export const REGISTRIES_REGISTRY = (id: string | undefined) => `${REGISTRIES}/${id ?? ":id"}`;

// ------------------------------ ПРОФИЛЬ ПОЛЬЗОВАТЕЛЯ

export const USER = "/user";

export const USER_PERSONAL_INFO = `${USER}/personal-info`;
export const USER_FAMILY_MEMBERS = `${USER}/family-members`;
export const USER_DOCUMENTS = `${USER}/documents`;

// ------------------------------ АНКЕТА ОБРАТНОЙ СВЯЗИ ПО ОРГАНИЗАЦИИ СКЛ И РОМ

export const FEEDBACK = "/feedback";

// ------------------------------ АДМИНИСТРИРОВАНИЕ

export const ADMINISTRATION = "/administration";

export const CALCULATION_SETTINGS = `${ADMINISTRATION}/calculation-settings`;
export const FREQUENCY_SETTINGS = `${ADMINISTRATION}/frequency-settings`;
export const REPORTING = (organization: string | undefined) =>
  `${ADMINISTRATION}/reporting${organization ? `organization/${organization}` : ""}`;
