// import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EntityRequestBaseType, notificationsApi } from "../api";
import { fetchAll, fetchTotalCount } from "../utils/fetchData";
import { getQueryFilter } from "../utils/getQueryFilter";
import {
  currentUserAtom,
  modalPageState,
  modalQuantityState,
  modalSearchQueryState,
} from "./index";

// ------------------------------ ВСЕ УВЕДОМЛЕНИЯ

export const notificationsAtom = atomWithQuery((get) => {
  const user = get(currentUserAtom);
  const page = get(modalPageState);
  const quantity = get(modalQuantityState);
  const queryState = get(modalSearchQueryState);

  const userId = user.res.id;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query, userId };

  return notificationsQuery(args);
});

export const notificationsState = loadable(notificationsAtom);

const notificationsQuery = (props: PropsType) => {
  const { startIndex, size, query, userId } = props;

  const filter = [
    {
      group: "AND",
      conditions: [
        { property: "recipient.id", operator: "=", value: userId },
        ...(query
          ? [
              getQueryFilter(query, [
                "businessKey",
                "additionalInformation",
                "sender.firstName",
                "sender.middleName",
                "sender.lastName",
              ]),
            ]
          : []),
      ],
    },
  ];

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "notificationsState"],
    queryFn: () => fetchAll(() => notificationsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountNotificationsAtom = atomWithQuery((get) => {
  const user = get(currentUserAtom);
  const queryState = get(modalSearchQueryState);

  const userId = user.res.id;

  const query = queryState || undefined;

  const args = { query, userId };

  return totalCountNotificationsQuery(args);
});

export const totalCountNotificationsState = loadable(totalCountNotificationsAtom);

const totalCountNotificationsQuery = (props: { query?: string; userId: string }) => {
  const { query, userId } = props;

  const filter = [
    {
      group: "AND",
      conditions: [
        { property: "recipient.id", operator: "=", value: userId },
        ...(query
          ? [
              getQueryFilter(query, [
                "businessKey",
                "sender.firstName",
                "sender.middleName",
                "sender.lastName",
              ]),
            ]
          : []),
      ],
    },
  ];

  const args = { startIndex: 0, size: 1, filter };

  return {
    queryKey: [query, "totalCountNotificationState"],
    queryFn: () => fetchTotalCount(() => notificationsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ КОЛИЧЕСТВО НОВЫХ УВЕДОМЛЕНИЙ

export const totalCountNewNotificationsAtom = atomWithQuery((get) => {
  const user = get(currentUserAtom);

  const userId = user.res.id;

  return totalCountNewNotificationsQuery(userId);
});

export const totalCountNewNotificationsState = loadable(totalCountNewNotificationsAtom);

const totalCountNewNotificationsQuery = (userId: string) => {
  const filter = [
    {
      group: "AND",
      conditions: [
        { property: "recipient.id", operator: "=", value: userId },
        { property: "isNewNotification", operator: "=", value: true },
      ],
    },
  ];

  const args = { startIndex: 0, size: 1, filter };

  return {
    queryKey: ["totalCountNewNotificationsState"],
    queryFn: () => fetchTotalCount(() => notificationsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ТИПЫ

type PropsType = EntityRequestBaseType & {
  userId: string;
};
