import { useFormikContext } from "formik";
import React, { FC, FocusEvent } from "react";
import { PropsSliderNumberType, SliderNumber } from "./SliderNumber";

export const SliderNumberInput: FC<PropsType> = (props) => {
  const { name } = props;

  const { handleBlur, setFieldValue } = useFormikContext();

  const onChange = (newValue: number) => setFieldValue(name, newValue);
  const onBlur = (evt: FocusEvent) => handleBlur(evt);

  return <SliderNumber {...props} onBlur={onBlur} onChange={onChange} editMode />;
};

type PropsType = PropsSliderNumberType & {
  name: string;
};
