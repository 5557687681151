import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { borders, colors, flex, margins, size } from "../../styles";

const { primaryDefaultDark, primaryActiveElementOpacity } = colors;

export const Overlay = styled.div<{ over: boolean }>`
  ${flex({ totalCenter: true })};
  ${size({ s: "100%" })};
  ${({ over }) =>
    over
      ? `border-radius: 18px 18px 0 0;
         background: ${primaryActiveElementOpacity};
         backdrop-filter: blur(2px);
         opacity: 0.5;`
      : undefined};

  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const Container = styled.div`
  ${size({ s: 40 })};
  ${borders({ totalRadius: true })};
  ${margins({ m: "100 auto" })};

  z-index: 99;
`;

export const Item = styled(CircularProgress)`
  ${size({ h: "100%" })};

  animation-duration: 5s;
  color: ${primaryDefaultDark};
  z-index: 99;
`;
