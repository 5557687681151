import { atom } from "jotai";
import { atomWithImmer } from "jotai/immer";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import {
  BooleanType,
  employeesApi,
  EmployeeType,
  EntityRequestBaseType,
  orgEmployeesApi,
} from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import {
  currentUserAtom,
  pageState,
  quantityInOnePageState,
  searchQueryState,
  UserType,
} from "../index";

export const employeesModalDataState = atom<EmployeeType | undefined>(undefined);

export const needUpdateVipAtom = atomWithImmer<boolean>(false);

// ------------------------------ ФИЛЬТРЫ

export const employeesAbsoluteInitialValues: FiltersType = {
  mainDepartment: "",
  organization: "",
  status: "ACTIVE",
  vip: "false",
};

export const employeesFiltersState = atom<FiltersType>(employeesAbsoluteInitialValues);

type FiltersType = {
  mainDepartment: string;
  organization: string;
  status: string;
  vip: BooleanType;
};

// ------------------------------ РАБОТНИКИ

const employeesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const filters = get(employeesFiltersState);
  const queryState = get(searchQueryState);
  const currentUser = get(currentUserAtom);
  const personnelNumbers = get(employeesWithVipAtom);

  const args = getArgs({ page, quantity, queryState, filters, currentUser, personnelNumbers });

  return employeesQuery(args);
});

export const employeesState = loadable(employeesAtom);

const employeesQuery = (args: PropsType) => {
  const { startIndex, size, query, organization, mainDepartment, status } = args;

  return {
    queryKey: [startIndex, size, query, organization, mainDepartment, status, "employeesState"],
    queryFn: () => fetchAll(() => orgEmployeesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ VIP РАБОТНИКИ

export const employeesWithVipAtom = atomWithQuery((get) => {
  const needUpdateVip = get(needUpdateVipAtom);

  return employeesWithVipQuery(needUpdateVip);
});

export const employeesWithVipState = loadable(employeesWithVipAtom);

const employeesWithVipQuery = (upd: boolean) => ({
  queryKey: [upd, "employeesWithVipAtom"],
  queryFn: async () => (await employeesApi().getVipEmployeeIds()).data,
});

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountEmployeesAtom = atomWithQuery((get) => {
  const filters = get(employeesFiltersState);
  const queryState = get(searchQueryState);
  const currentUser = get(currentUserAtom);
  const personnelNumbers = get(employeesWithVipAtom);

  const args = getArgs({
    page: 0,
    quantity: 1,
    queryState,
    filters,
    currentUser,
    personnelNumbers,
  });

  return totalCountEmployeesQuery(args);
});

export const totalCountEmployeesState = loadable(totalCountEmployeesAtom);

const totalCountEmployeesQuery = (args: PropsType) => {
  const { query, organization, mainDepartment, status } = args;

  return {
    queryKey: [query, organization, mainDepartment, status, "totalCountEmployeesState"],
    queryFn: () => fetchTotalCount(() => orgEmployeesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ВЫЧИСЛЕНИЕ АРГУМЕНТОВ

const getArgs = (props: ProcessArgsPropsType) => {
  const { page, quantity, queryState, filters, currentUser, personnelNumbers } = props;

  const { roleModel, res } = currentUser;
  const { canViewAllEmployees, canViewOrganizationEmployees } = roleModel;
  const { department: userDepartment, organization: userOrganization } = res;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const canSeeDepartments = canViewAllEmployees || canViewOrganizationEmployees;
  const canSeeOrgs = canViewAllEmployees;
  const mainDepartment = canSeeDepartments ? filters.mainDepartment || undefined : userDepartment;
  const organization = canSeeOrgs ? filters.organization || undefined : userOrganization;

  const status = filters.status || "ACTIVE";
  const vip = filters.vip === "true";

  return {
    ...(startIndex || startIndex === 0 ? { from: startIndex } : {}),
    ...(size || size === 0 ? { size } : {}),
    ...(query ? { searchQuery: query } : {}),
    ...(organization ? { organization } : {}),
    ...(mainDepartment ? { mainDepartment } : {}),
    ...(status ? { status } : {}),
    ...(vip ? { personnelNumbers: `${personnelNumbers.length ? personnelNumbers : ["—"]}` } : {}),
  };
};

// ------------------------------ ТИПЫ

type PropsType = Partial<EntityRequestBaseType> & {
  organization?: string;
  mainDepartment?: string;
  personnelNumbers?: string;
  status?: string;
  vip?: boolean;
};

type ProcessArgsPropsType = {
  page: number;
  quantity: number;
  queryState: string;
  filters: FiltersType;
  currentUser: UserType;
  personnelNumbers: string[];
};
