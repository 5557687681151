import React, { FC } from "react";
import { Rating, RatingReviews, RatingScore } from "./RoomsSanatoriumCard.styles";

export const RatingBlock: FC<RatingBasePropsType> = (props) => {
  const { averageRating, allRating } = props;

  return averageRating ? (
    <Rating>
      <RatingScore>{averageRating}</RatingScore>
      {allRating && <RatingReviews>{allRating} оценок</RatingReviews>}
    </Rating>
  ) : null;
};

export type RatingBasePropsType = {
  averageRating?: string;
  allRating?: string;
};
