export const sorting = {
  resort_Country: "+name",
  resort_Region: "+name",
  resort_MedicalProfile: "+name",
  resort_Treatment: "+name",
  resort_Service: "+name",
  resort_ConditionClass: "+name",
  resort_HarmfulFactor: "+name",
  resort_MedicalProgram: "+name",
  resort_Aspect: "+name",
  resort_AspectGroup: "+name",
  resort_Improvement: "+name",
  resort_Effectiveness: "+name",
  resort_Status: "+name",
  resort_DocumentType: "+name",
  resort_RefusalReason: "+name",
  resort_BusinessProcessNotification: "-createdDate",
};
