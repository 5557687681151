import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { frequencySettingsApi, IdType } from "../../api";
import {
  DataItemType,
  FrequencyDataObjectType,
  TableFrequencyDataType,
} from "../../pages/AdministrationPages/FrequencySettingsPage/hooks/useData";
import { fetchAll } from "../../utils/fetchData";

export const frequencySettingsModalDataState = atom<FrequencyDataObjectType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const frequencySettingsState = atomWithQuery(() => ({
  queryKey: "frequencySettingsState",
  queryFn: () => fetchAll(() => frequencySettingsApi().getAll()),
}));

export const changesFrequencySettingsState = atom<ChangesFrequencySettingsType>({
  create: [],
  update: {},
  delete: [],
});

export const dataState = atom<TableFrequencyDataType | null>(null);

type ChangesFrequencySettingsType = {
  update: UpdateChangesType;
  delete: DeleteChangesType;
  create: CreateChangesType;
};

export type CreateChangesType = (DataItemType &
  Pick<FrequencyDataObjectType, "factorId" | "classId" | "code">)[];

export type UpdateChangesType = Record<string, DataItemType>;

export type DeleteChangesType = IdType[];
