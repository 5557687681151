import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EntityRequestBaseType, regionsApi, RegionType } from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { pageState, quantityInOnePageState, searchQueryState } from "../index";

export const regionsModalDataState = atom<RegionType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const regionsAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return regionsQuery(args);
});

export const regionsState = loadable(regionsAtom);

const regionsQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "country.name"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "regionsState"],
    queryFn: () => fetchAll(() => regionsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountRegionsAtom = atomWithQuery((get) => {
  const queryState = get(searchQueryState);

  const query = queryState || undefined;

  const args = { query };

  return totalCountRegionsQuery(args);
});

export const totalCountRegionsState = loadable(totalCountRegionsAtom);

const totalCountRegionsQuery = (props: { query?: string }) => {
  const { query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "country.name"])] : undefined;

  const args = { startIndex: 0, size: 1, query, filter };

  return {
    queryKey: [query, "totalCountRegionsState"],
    queryFn: () => fetchTotalCount(() => regionsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const regionsAllState = atomWithQuery(() => ({
  queryKey: ["regionsAllState"],
  queryFn: () => fetchAll(() => regionsApi().getAll()),
}));
