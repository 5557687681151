import * as DOMPurify from "dompurify";
import { useField } from "formik";
import debounce from "lodash/debounce";
import React, { FC, useCallback } from "react";
import "react-quill/dist/quill.snow.css";
import { Error } from "../NumberInput/NumberInput.styles";
import { Quill } from "./StyledTextInput.styles";

export const StyledTextInput: FC<PropsType> = (props) => {
  const { name } = props;

  const [{ value }, { error }, { setValue }] = useField<string>(name);

  const modules = {
    toolbar: [[{ header: [1, 2, false] }], ["bold", "italic"], ["clean"], [{ align: [] }]],
  };

  const debouncedHandleChange = useCallback(
    debounce((evt: string) => setValue(evt), 100),
    []
  );

  const handleChange = (evt: string) => {
    debouncedHandleChange(evt);
  };

  return (
    <>
      <Quill
        // todo: для этой библиотеки отображается предупреждение:
        //       Listener added for a 'DOMNodeInserted' mutation event. This event type is deprecated, and will be removed from this browser very soon.
        //       Usage of this event listener will cause performance issues today, and represents a large risk of future site breakage.
        //       Consider using MutationObserver instead. See https://chromestatus.com/feature/5083947249172480 for more information.
        error={!!error}
        modules={modules}
        theme="snow"
        value={DOMPurify.sanitize(value)}
        onChange={handleChange}
      />

      <Error>{error}</Error>
    </>
  );
};

type PropsType = {
  name: string;
  placeholder: string;
};
