import { css } from "@emotion/react";
import { ColorsType } from "../api";

// todo: уточнить последовательность none и border со значением

export const borders = (props: PropsType) => {
  const { side, width, style = "solid", color, none = false, radius, totalRadius = false } = props;

  return css`
    ${none
      ? `border${typeof none === "string" ? `-${none}` : ""}: none;  outline: none`
      : undefined};

    ${color ? `border${side ? `-${side}` : ""}: ${width ?? 1}px ${style} ${color}` : undefined};

    ${radius ? `border-radius: ${typeof radius === "number" ? `${radius}px` : radius}` : undefined};

    ${totalRadius ? "border-radius: 99em" : undefined};
  `;
};

type PropsType = {
  side?: SideType;
  width?: number;
  color?: ColorsType;
  style?: "solid" | "dashed";
  none?: SideType | boolean;
  radius?: number | `${number}%`;
  totalRadius?: boolean;
};

type SideType = "top" | "bottom" | "left" | "right";
