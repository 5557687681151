import { Badge } from "@digitalatom/ui";
import { useAtomValue } from "jotai/utils";
import React, { FC, ReactNode } from "react";
import { SanatoriumWithDetailRatingType } from "../../../api";
import { aspectGroupsState } from "../../../atoms";
import { Columns } from "../../../styles";
import { HtmlContent } from "../../../uiKit";
import { SanatoriumFeatureList } from "../../RoomsSanatoriumCard/SanatoriumFeatureList/SanatoriumFeatureList";
import { SanatoriumRatingCard } from "../../RoomsSanatoriumCard/SanatoriumRatingCard/SanatoriumRatingCard";
import { ServicesView } from "../../RoomsSanatoriumCard/ServicesView/ServicesView";
import { CardBase } from "../components/CardBase/CardBase";
import { SubBlock } from "../components/SubBlock/SubBlock";
import * as elements from "./SanatoriumCard.styles";

export const SanatoriumCard: FC<PropsType> = (props) => {
  const { sanatorium, needChange, isVertical } = props;
  const aspectGroupsStateData = useAtomValue(aspectGroupsState);
  const aspectGroups =
    aspectGroupsStateData.state === "hasData" ? aspectGroupsStateData.data : undefined;

  const { overallRatingOverridden, photos, containsRehab } = sanatorium;
  const { description, profiles, address, region, treatments, services } = sanatorium;
  const { ratingGroups, ratingCount } = sanatorium;

  const { Activity, MainInfo, MainInfoWithoutActivity, Rating } = elements;
  const { RatingReviews, RatingScore, SubBlocks, RatingBlockContainer } = elements;

  const ratingId = "rating";

  const ratingInfo = (clickable: boolean) => (
    <Rating to={ratingId} containerId="pageContainer" smooth clickable={+clickable}>
      <RatingScore>{overallRatingOverridden ?? 0}</RatingScore>
      <RatingReviews>{ratingCount || "Нет"} оценок</RatingReviews>
    </Rating>
  );

  return (
    <CardBase photos={photos} isVertical={!!needChange || !!isVertical}>
      <MainInfo>
        <MainInfoWithoutActivity>
          {ratingInfo(true)}

          <Badge text="СКЛ" design="minor" type="accent" />
          {containsRehab && <Badge text="РОМ" design="minor" type="accent" />}
        </MainInfoWithoutActivity>

        {needChange && <Activity>{needChange}</Activity>}
      </MainInfo>

      <SubBlocks>
        <Columns columns={2}>
          <SubBlock title="Регион" content={region.name} />
          <SubBlock title="Страна" content={region.country.name} />
        </Columns>

        <SubBlock title="Адрес" content={address} />
        <SubBlock title="Профили лечения" content={<SanatoriumFeatureList profiles={profiles} />} />
        <SubBlock
          title="Методы лечения"
          content={<SanatoriumFeatureList treatments={treatments} />}
        />
        <SubBlock title="Услуги санатория" content={<ServicesView services={services} />} />
        <SubBlock title="Описание" content={<HtmlContent html={description} />} />
        <SubBlock
          title="Рейтинг"
          id={ratingId}
          content={
            <RatingBlockContainer>
              {ratingInfo(false)}

              <SanatoriumRatingCard aspectGroups={aspectGroups} aspectsValues={ratingGroups} />
            </RatingBlockContainer>
          }
        />
      </SubBlocks>
    </CardBase>
  );
};

type PropsType = {
  sanatorium: SanatoriumWithDetailRatingType;
  needChange?: ReactNode;
  isVertical?: boolean;
};
