import { DateRange } from "@mui/lab";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable, useAtomValue } from "jotai/utils";
import { lifecycleApi, ProcessTypeType } from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { formatDate } from "../../utils/workingWithDates";
import {
  applicationsTypeState,
  ApplicationsTypeType,
  currentDateInterval,
  pageState,
  quantityInOnePageState,
  searchQueryState,
} from "../index";

// ------------------------------ ФИЛЬТРЫ

export const tasksAbsoluteInitialValues: FiltersType = {
  status: undefined,
  sanatorium: "",
  since: undefined,
  until: undefined,
};

export const taskFiltersState = atom<FiltersType>(tasksAbsoluteInitialValues);

// ------------------------------ ЗАДАЧИ

const tasksAtom = atomWithQuery((get) => {
  const applicationsType = get(applicationsTypeState);
  const currentDate = get(currentDateInterval);
  const filters = get(taskFiltersState);
  const query = get(searchQueryState);
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);

  const args = { applicationsType, currentDate, filters, query, page, quantity };

  return tasksQuery(args);
});

export const tasksState = loadable(tasksAtom);

const tasksQuery = (props: TasksPropsType) => {
  const { applicationsType } = props;

  const { args, keys } = processTasksArgs(props);

  return {
    queryKey: [...keys, applicationsType, "applicationsState"],
    queryFn: () =>
      fetchAll(() =>
        applicationsType === "inbox"
          ? lifecycleApi.getMyGroupTasks(args)
          : applicationsType === "in-progress"
          ? lifecycleApi.getMyTasks(args)
          : lifecycleApi.getMyCompletedTasks(args)
      ),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО ЗАДАЧ

const applicationsTotalCountAtom = atomWithQuery((get) => {
  const applicationsType = get(applicationsTypeState);
  const currentDate = get(currentDateInterval);
  const filters = get(taskFiltersState);
  const query = get(searchQueryState);
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);

  const args = { applicationsType, currentDate, filters, query, page, quantity };

  return applicationsTotalCountQuery(args);
});

export const applicationsTotalCountState = loadable(applicationsTotalCountAtom);

const applicationsTotalCountQuery = (props: TasksPropsType) => {
  const { applicationsType } = props;

  const { args, keys } = processTasksArgs(props);

  return {
    queryKey: [...keys, applicationsType, "applicationsTotalCountState"],
    queryFn: () =>
      fetchTotalCount(() =>
        applicationsType === "inbox"
          ? lifecycleApi.getMyGroupTasks(args)
          : applicationsType === "in-progress"
          ? lifecycleApi.getMyTasks(args)
          : lifecycleApi.getMyCompletedTasks(args)
      ),
    keepPreviousData: true,
  };
};

// ------------------------------ ЗАДАЧИ - ВХОДЯЩИЕ - ОБЩЕЕ КОЛИЧЕСТВО

const inboxApplicationsTotalCountAtom = atomWithQuery(() => {
  const args = { process: "APPLICATION" as ProcessTypeType, size: 1 };

  return {
    queryKey: ["inboxApplicationsTotalCountState"],
    queryFn: () => fetchTotalCount(() => lifecycleApi.getMyGroupTasks(args)),
    keepPreviousData: true,
  };
});

export const inboxApplicationsTotalCountState = loadable(inboxApplicationsTotalCountAtom);

// ------------------------------ ЗАДАЧИ - В РАБОТЕ - ОБЩЕЕ КОЛИЧЕСТВО

const applicationsInProgressTotalCountAtom = atomWithQuery(() => {
  const args = { process: "APPLICATION" as ProcessTypeType, size: 1 };

  return {
    queryKey: ["applicationsInProgressTotalCountState"],
    queryFn: () => fetchTotalCount(() => lifecycleApi.getMyTasks(args)),
    keepPreviousData: true,
  };
});

export const applicationsInProgressTotalCountState = loadable(applicationsInProgressTotalCountAtom);

// ------------------------------ АРГУМЕНТЫ и КЛЮЧИ

export const useTasksKeys = () => {
  const applicationsType = useAtomValue(applicationsTypeState);
  const currentDate = useAtomValue(currentDateInterval);
  const filters = useAtomValue(taskFiltersState);
  const query = useAtomValue(searchQueryState);
  const page = useAtomValue(pageState);
  const quantity = useAtomValue(quantityInOnePageState);

  const args = { applicationsType, currentDate, filters, query, page, quantity };

  return processTasksArgs(args).keys;
};

const processTasksArgs = (props: ProcessTasksArgsType) => {
  const { filters, currentDate, query, page, quantity, applicationsType } = props;

  const process = "APPLICATION" as ProcessTypeType;

  const statusId = filters.status || undefined;
  const sanatoriumId = filters.sanatorium || undefined;
  const startDate = filters.since
    ? formatDate({ date: filters.since, type: "forBackend" })
    : undefined;
  const endDate = filters.until
    ? formatDate({ date: filters.until, type: "forBackend" })
    : undefined;
  const year = currentDate[0]?.getFullYear();
  const fullNameQuery = query || undefined;

  const from = page * quantity;
  const size = quantity;

  const args = {
    process,
    statusId,
    sanatoriumId,
    ...(!["inbox", "in-progress"].includes(applicationsType as string)
      ? { startDate, endDate }
      : {}),
    year,
    fullNameQuery,
    from,
    size,
  };

  const keys = [
    process,
    statusId,
    sanatoriumId,
    startDate,
    endDate,
    year,
    fullNameQuery,
    from,
    size,
  ];

  return { keys, args };
};

type ProcessTasksArgsType = Pick<
  TasksPropsType,
  "filters" | "currentDate" | "query" | "page" | "quantity" | "applicationsType"
>;

type TasksPropsType = {
  applicationsType: Omit<ApplicationsTypeType, "all" | "my">;
  currentDate: DateRange<Date>;
  filters: FiltersType;
  query: string;
  page: number;
  quantity: number;
};

type FiltersType = {
  status?: ProcessTypeType;
  sanatorium: "";
  since?: Date;
  until?: Date;
};
