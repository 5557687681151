import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../styles";
import { CheckInputLabelTypeType } from "./CheckInput";

const { white, grayscalePlaceholder, grayscaleBody, grayscaleBeautifulBlack, grayscaleIcons } =
  colors;

const labelStyles: Record<CheckInputLabelTypeType, (dis?: boolean) => SerializedStyles> = {
  cursive: (disabled) =>
    text({
      size: 17,
      height: 24,
      weight: 400,
      color: disabled ? grayscalePlaceholder : grayscaleBeautifulBlack,
    }),
  default: (disabled) =>
    text({
      size: 17,
      height: 24,
      weight: 700,
      color: disabled ? grayscalePlaceholder : grayscaleBeautifulBlack,
    }),
  gray: (disabled) =>
    text({
      size: 15,
      height: 19,
      weight: 400,
      color: disabled ? grayscalePlaceholder : grayscaleBody,
    }),
};

export const Container = styled.div<ContainerPropsType>`
  ${flex({ gap: 8, vertical: "center" })};
  ${({ disabled, labelType }) => labelStyles[labelType](disabled)};
  ${({ needMargin }) => margins({ m: needMargin ? "0 0 16 0" : "0" })};
`;

export const Info = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 20 })};
  ${borders({ totalRadius: true })};
  ${text({ size: 13, height: 18, weight: 700, color: white })};

  background: ${grayscaleIcons};
`;

type ContainerPropsType = {
  needMargin: boolean;
  labelType: CheckInputLabelTypeType;
  disabled?: boolean;
};
