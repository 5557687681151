import { RehabActivityTypeType } from "../constants/options";
import { hrApi } from "./api";
import { entitiesBase, EntityNameType } from "./request";
import { EntityRequestBaseType, IdType, ProcessTypeType, StatusColorsType } from "./types";

// ------------------------------ МЕЛКИЕ СПРАВОЧНИКИ (кроме справочников работников и санаториев)

const entities = <DATA, VOBJ>(entityName: EntityNameType) =>
  entitiesBase<DATA, VOBJ, EntityRequestBaseType>(hrApi, entityName);

export const countriesApi = <VOBJ>() => entities<LittleOtherType, VOBJ>("resort_Country");
export const regionsApi = <VOBJ>() => entities<RegionType, VOBJ>("resort_Region");
export const treatmentProfilesApi = <VOBJ>() =>
  entities<BigOtherType, VOBJ>("resort_MedicalProfile");
export const treatmentMethodsApi = <VOBJ>() =>
  entities<TreatmentMethodType, VOBJ>("resort_Treatment");
export const sanatoriumServicesApi = <VOBJ>() =>
  entities<OtherWithIconType, VOBJ>("resort_Service");
export const workingConditionsClassesApi = <VOBJ>() =>
  entities<WorkingConditionsClassType, VOBJ>("resort_ConditionClass");
export const occupationalFactorsApi = <VOBJ>() =>
  entities<OtherWithIconType, VOBJ>("resort_HarmfulFactor");
export const typicalMedicalProgramsApi = <VOBJ>() =>
  entities<BigOtherType, VOBJ>("resort_MedicalProgram");
export const sanatoriumAspectsApi = <VOBJ>() =>
  entities<SanatoriumAspectType, VOBJ>("resort_Aspect");
export const aspectGroupsApi = <VOBJ>() => entities<LittleOtherType, VOBJ>("resort_AspectGroup");
export const improveServicesApi = <VOBJ>() => entities<MediumOtherType, VOBJ>("resort_Improvement");
export const patientOutcomesApi = <VOBJ>() =>
  entities<MediumOtherType, VOBJ>("resort_Effectiveness");
export const statusesApi = <VOBJ>() => entities<StatusType, VOBJ>("resort_Status");
export const documentTypesApi = <VOBJ>() => entities<MediumOtherType, VOBJ>("resort_DocumentType");
export const whyNotApi = <VOBJ>() => entities<LittleOtherType, VOBJ>("resort_RefusalReason");
export const rehabActivitiesApi = <VOBJ>() =>
  entities<RehabActivityType, VOBJ>("resort_RefusalReason"); // todo: поменять эндпоинт

// ------------------------------ ТИПЫ - МЕЛКИЕ СПРАВОЧНИКИ

export type LittleOtherType = IdType & { name: string };
export type MediumOtherType = LittleOtherType & { description: string };
export type BigOtherType = MediumOtherType & { shortName: string };
export type OtherWithIconType = MediumOtherType & { icon: string };

export type RegionType = LittleOtherType & { country: IdType & { name: string } };
export type StatusType = LittleOtherType & { type: ProcessTypeType; color: StatusColorsType };
export type WorkingConditionsClassType = OtherWithIconType & { code: string };
export type SanatoriumAspectType = MediumOtherType & { group: LittleOtherType };
export type TreatmentMethodType = BigOtherType & { containsRehab: boolean };
export type RehabActivityType = IdType & {
  type: RehabActivityTypeType;
  name: string;
  programs: string[];
};
