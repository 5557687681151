import { Formik } from "formik";
import { useAtom } from "jotai";
import React from "react";
import { filtersFormState } from "../../atoms";
import { FormContainer } from "../../styles";
import { FormButtons } from "../Form";
import { MethodsType } from "../Page";
import { RightModal, RightModalActions, RightModalBody } from "../RightModal";

export const Filters = <DATUM, OBJ, FLDS, VOBJ, FLTRS>(
  props: PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>
) => {
  const { children, methods } = props;

  const { api, queryKey } = methods;
  const { filtersFormName = "filtersForm", filtersFormTitle, fieldsFilters, setFilters } = methods;
  const { absoluteInitialValues, initialValuesFilters, validationSchemaFilters } = methods;

  const [filtersFormIsOpen, setFiltersFormIsOpen] = useAtom(filtersFormState);

  const handleClose = () => setFiltersFormIsOpen(false);

  if (!api || !queryKey) return null;

  return (
    <RightModal
      title={filtersFormTitle ?? "Фильтры"}
      isOpen={filtersFormIsOpen}
      onClose={handleClose}
      size="small"
    >
      <Formik
        initialValues={initialValuesFilters ?? {}}
        validationSchema={validationSchemaFilters}
        onSubmit={async (values) => {
          if (!setFilters) return;

          setFilters(values as FLTRS);

          handleClose();
        }}
      >
        {({ handleSubmit, setValues, values, dirty, isValid }) => (
          <>
            {children ? (
              children
            ) : (
              <RightModalBody>
                <FormContainer id={filtersFormName} onSubmit={handleSubmit}>
                  {typeof fieldsFilters === "function"
                    ? fieldsFilters(values as FLTRS)
                    : fieldsFilters}
                </FormContainer>
              </RightModalBody>
            )}

            <RightModalActions>
              <FormButtons
                formName={filtersFormName}
                onClose={handleClose}
                saveOrAdd="clear"
                disabled={!dirty || !isValid}
                onClear={() => setValues(absoluteInitialValues ?? {})}
              />
            </RightModalActions>
          </>
        )}
      </Formik>
    </RightModal>
  );
};

type PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS> = {
  methods: MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>;
  children?: JSX.Element;
};
