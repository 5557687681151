import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { BigOtherType, EntityRequestBaseType, treatmentMethodsApi } from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { pageState, quantityInOnePageState, searchQueryState } from "../index";

export const treatmentMethodsModalDataState = atom<BigOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const treatmentMethodsAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return treatmentMethodsQuery(args);
});

export const treatmentMethodsState = loadable(treatmentMethodsAtom);

const treatmentMethodsQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "shortName"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "treatmentMethodsState"],
    queryFn: () => fetchAll(() => treatmentMethodsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountTreatmentMethodsAtom = atomWithQuery((get) => {
  const queryState = get(searchQueryState);

  const query = queryState || undefined;

  const args = { query };

  return totalCountTreatmentMethodsQuery(args);
});

export const totalCountTreatmentMethodsState = loadable(totalCountTreatmentMethodsAtom);

const totalCountTreatmentMethodsQuery = (props: { query?: string }) => {
  const { query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "shortName"])] : undefined;

  const args = { startIndex: 0, size: 1, filter };

  return {
    queryKey: [query, "totalCountTreatmentMethodsState"],
    queryFn: () => fetchTotalCount(() => treatmentMethodsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const treatmentMethodsAllState = atomWithQuery(() => ({
  queryKey: ["treatmentMethodsAllState"],
  queryFn: () => fetchAll(() => treatmentMethodsApi().getAll()),
}));
