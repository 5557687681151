import styled from "@emotion/styled";
import { borders } from "./borderStyles";
import { colors } from "./colors";
import { flex } from "./displayStyles";
import { margins } from "./marginsStyles";
import { size } from "./sizeStyles";
import { text } from "./textStyles";

const { grayscalePlaceholder, redDefault, grayscaleBeautifulBlack } = colors;

export const HyphenForInput = styled.span<{ needLabel: boolean }>`
  ${text({ size: 16, height: 23, weight: 400, color: grayscalePlaceholder, align: "center" })};
  ${({ needLabel }) => (needLabel ? margins({ m: "12 0 0" }) : undefined)};
`;

// todo: возможно ли для всех инпутов положение ошибки bottom сделать одинаковым?
//       например, поменять на top: calc(100% + 2px);
export const Error = styled.p<{ bottom?: number }>`
  ${size({ w: "95%" })};
  ${text({ size: 12, height: 12, weight: 400, color: redDefault })};

  position: absolute;
  left: 16px;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "-12px")};
  white-space: nowrap;
  z-index: 1;
`;

export const FormContainer = styled.form`
  ${flex({ gap: 42, isColumn: true, vertical: "evenly" })};
  ${size({ h: "100%" })};
`;

export const FieldsBlock = styled.div`
  ${flex({ gap: 16, isColumn: true })};
  ${margins({ m: "0 0 16 0" })};
`;

export const FormSubTitle = styled.div`
  ${flex({ gap: 16, horizontal: "left" })};
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ m: "32 0 8" })};

  :first-of-type {
    ${margins({ m: "0 0 8" })};
  }
`;

export const MenuButtons = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ p: "8" })};
`;

export const MenuButton = styled.div`
  cursor: pointer;
`;

export const Label = styled.div<{ color: string }>`
  ${flex({ gap: 12, vertical: "center" })};
  ${size({ h: 32 })};
  ${borders({ totalRadius: true })};
  ${text({ size: 15, height: 20, weight: 400 })};
  ${margins({ p: "0 8" })};

  background: ${({ color }) => color};
`;
