import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { LittleOtherType, whyNotApi } from "../../api";
import { fetchAll } from "../../utils/fetchData";

export const whyNotModalDataState = atom<LittleOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const whyNotAtom = atomWithQuery(() => ({
  queryKey: "whyNotState",
  queryFn: () => fetchAll(() => whyNotApi().getAll()),
}));

export const whyNotState = loadable(whyNotAtom);

// ------------------------------ ДАННЫЕ - ВСЕ

export const whyNotAllState = atomWithQuery(() => ({
  queryKey: ["whyNotAllState"],
  queryFn: () => fetchAll(() => whyNotApi().getAll()),
}));
