import * as dateFns from "date-fns";
import { parseISO } from "date-fns";
import { BackendDateType, BigBackendDateType, FrontendDateType } from "../api";
import { DateSwitcherTypeType } from "../atoms";
import { arrMonths, arrYears } from "../constants/dates";
import { getInclinedWord } from "./getInclinedWord";

const { differenceInYears, differenceInMonths, differenceInDays } = dateFns;
const { format, parse, startOfDay, getTime } = dateFns;
const { addDays, addMonths, addQuarters, addYears } = dateFns;
const { subDays, subMonths, subQuarters, subYears } = dateFns;
const { startOfISOWeek, startOfMonth, startOfQuarter, startOfYear } = dateFns;
const { endOfISOWeek, endOfMonth, endOfQuarter, endOfYear } = dateFns;

// ------------------------------ НАЧАЛО И КОНЕЦ ТЕКУЩЕГО ДНЯ

export const now = new Date();
export const today = startOfDay(now);

type DateType = Date | FrontendDateType | BackendDateType | BigBackendDateType;

/**
 *
 * ------------------------------------------------------------------------------------------
 * ОПРЕДЕЛЕНИЕ ДЕЙСТВИТЕЛЬНОСТИ ДАТЫ
 *
 * -
 *
 * @param date - предполагаемый объект даты
 *
 */

export const isDateObject = (date: unknown) => date instanceof Date && !isNaN(getTime(date));

/**
 *
 * ------------------------------------------------------------------------------------------
 * ФОРМАТИРОВАНИЕ ДАТЫ
 *
 * -
 *
 * @param props - параметры
 * @param props.date - дата
 * @param props.type - тип форматирования
 *
 * @description
 * "object" - для получения объекта даты
 * "forFrontend" - для получения даты в формате дд.ММ.гггг
 * "forBackend" - для получения даты в формате гггг-ММ-дд
 *
 */

export const formatDate = <T extends TypeType>(props: FormatDatePropsType<T>) => {
  const { date, type } = props;

  const dateIsString = typeof date === "string";
  const dateIncludesT = dateIsString && date.includes("T");
  const dateIncludesDash = dateIsString && date.includes("-");

  const fMask = "dd.MM.yyyy";
  const bMask = "yyyy-MM-dd";

  const dateObject = dateIsString
    ? dateIncludesT
      ? parseISO(date)
      : parse(date, dateIncludesDash ? bMask : fMask, new Date())
    : date;

  const dateForFrontend = format(dateObject, fMask);
  const dateForBackend = format(dateObject, bMask);

  return (
    type === "object" ? dateObject : type === "forFrontend" ? dateForFrontend : dateForBackend
  ) as FormatDateResultType<T>;
};

type FormatDatePropsType<T> = {
  date: DateType;
  type: T;
};

type FormatDateResultType<T> = T extends "object"
  ? Date
  : T extends "forFrontend"
  ? FrontendDateType
  : BackendDateType;

type TypeType = "object" | "forFrontend" | "forBackend";

/**
 *
 * ------------------------------------------------------------------------------------------
 * ВЫЧИСЛЕНИЕ КОЛИЧЕСТВА ДНЕЙ/НОЧЕЙ
 *
 * -
 *
 * @param since - начало периода
 * @param until - конец периода
 * @param type - тип результата - дни или ночи
 *
 */

export const getDaysOrNightsInPeriod = (props: GetDaysOrNightsInPeriodPropsType) => {
  const { since, until, type = "days" } = props;

  const sinceDate = formatDate({ date: since, type: "object" });
  const untilDate = formatDate({ date: until, type: "object" });

  const nights = differenceInDays(untilDate, sinceDate);

  return type === "days" ? nights + 1 : nights;
};

type GetDaysOrNightsInPeriodPropsType = {
  since: DateType;
  until: DateType;
  type?: "days" | "nights";
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ВЫЧИСЛЕНИЕ РАЗНИЦЫ МЕЖДУ СЕЙЧАС И ОПРЕДЕЛЁННОЙ ДАТОЙ
 * например, возраста или стажа
 * в годах, месяцах или днях
 * функция возвращает модуль вычиесленного значения, т.е. вне зависимости от того дата до иил после сегодня
 *
 * -
 *
 * @param date - дата, разница с которой вычисляется
 * @param period - "year" | "month" | "day"
 *
 */

export const differenceWithToday = (date: DateType, period: PeriodType = "year") => {
  const dateObject = formatDate({ date, type: "object" });

  const differenceMapper: Record<PeriodType, number> = {
    day: differenceInDays(today, dateObject),
    month: differenceInMonths(today, dateObject),
    year: differenceInYears(today, dateObject),
  };

  return differenceMapper[period];
};

type PeriodType = "year" | "month" | "day";

/**
 *
 * ------------------------------------------------------------------------------------------
 * ВЫЧИСЛЕНИЕ РАЗНИЦЫ МЕЖДУ СЕЙЧАС И ОПРЕДЕЛЁННОЙ ДАТОЙ
 * например, возраста или стажа
 * в месяцах, если менее года и в годах и месяцах - если более
 * в результате получаем строку для отображения в интерфейсе
 *
 * -
 *
 * @param date - дата, разница с которой вычисляется
 * @param onlyYear - указывает на необходимость получения только численного значения в годах
 *
 */

export const differenceWithTodayText = (date: DateType) => {
  const yearsQuantity = differenceWithToday(date);
  const monthsQuantity = differenceWithToday(date, "month") % 12;

  const yearsStr = `${yearsQuantity} ${getInclinedWord(yearsQuantity, arrYears)}`;
  const monthsStr = `${monthsQuantity} ${getInclinedWord(monthsQuantity, arrMonths)}`;

  return yearsQuantity === 0 ? monthsStr : `${yearsStr} ${monthsStr}`;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ СТРОКИ ПЕРИОДА
 * должна быть передана хотя бы одна дата
 *
 * -
 *
 * @param since - начало периода
 * @param until - конец периода
 * @param withText - указание на то, что период требуется в формате "с/по", а не через дефис - если не передан, то формат через дефис
 *
 */

export const getPeriodString = (props: GetPeriodStringPropsType) => {
  const { since, until, withText } = props as GetPeriodStringType;

  const sinceDate = since && formatDate({ date: since, type: "forFrontend" });
  const untilDate = until && formatDate({ date: until, type: "forFrontend" });

  const sinceString = since ? `${withText ? "с " : ""}${sinceDate}` : "";
  const untilString = until
    ? `${withText ? (since ? " по " : "по ") : since ? " — " : "до "}${untilDate}`
    : "";

  return `${sinceString}${untilString}`;
};

type GetPeriodStringPropsType = {
  since: DateType | null | undefined;
  until: DateType | null | undefined;
  withText?: boolean;
};

type GetPeriodStringType = {
  since?: DateType | null | undefined;
  until?: DateType | null | undefined;
  withText?: boolean;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * МЕТОДЫ ПОЛУЧЕНИЯ НАЧАЛА И КОНЦА ПЕРИОДА
 *
 * -
 *
 * @param start.year - начало года
 * @param start.quarter - начало квартала
 * @param start.month - начало месяца
 * @param start.period - начало периода
 * @param end.year - конец года
 * @param end.quarter - конец квартала
 * @param end.month - конец месяца
 * @param end.period - конец периода
 *
 */

export const startAndEndMethods = {
  start: {
    year: startOfYear,
    quarter: startOfQuarter,
    month: startOfMonth,
    period: startOfISOWeek,
  },
  end: {
    year: endOfYear,
    quarter: endOfQuarter,
    month: endOfMonth,
    period: endOfISOWeek,
  },
} as Record<"start" | "end", Record<DateSwitcherTypeType, (date: Date | number) => Date>>;

/**
 *
 * ------------------------------------------------------------------------------------------
 * МЕТОДЫ ДОБАВЛЕНИЯ/УДАЛЕНИЯ ЕДИНИЦЫ ПЕРИОДА
 *
 * -
 *
 * type DateSwitcherTypeType = "day" | "month" | "quarter" | "year" | "period"
 *
 * -
 *
 * @param plus.year - плюс 1 год
 * @param plus.quarter - плюс 1 квартал
 * @param plus.month - плюс 1 месяц
 * @param plus.day - плюс 1 день
 * @param minus.year - минус 1 год
 * @param minus.quarter - минус 1 квартал
 * @param minus.month - минус 1 месяц
 * @param minus.day - минус 1 день
 *
 */

export const onePeriodMethods = {
  plus: {
    year: (date: Date) => addYears(date, 1),
    quarter: (date: Date) => addQuarters(date, 1),
    month: (date: Date) => addMonths(date, 1),
    day: (date: Date) => addDays(date, 1),
  },
  minus: {
    year: (date: Date) => subYears(date, 1),
    quarter: (date: Date) => subQuarters(date, 1),
    month: (date: Date) => subMonths(date, 1),
    day: (date: Date) => subDays(date, 1),
  },
} as Record<"plus" | "minus", Record<DateSwitcherTypeType, (date: Date) => Date>>;
