import styled from "@emotion/styled";
import {
  borders,
  colors,
  flex,
  grid,
  margins,
  mediaSizes,
  overflowLines,
  shadow,
  size,
  text,
} from "../../styles";
import { BaseCardPropsType } from "./BaseCard";

const { blackOpacity, white, grayscaleLabel, grayscaleAsh, black } = colors;
const { grayscalePlaceholder, primaryDefault, redDefault, greenBackground, primaryBackground } =
  colors;

export const TitleContainer = styled.div`
  ${flex({ gap: 8, horizontal: "evenly" })};
`;
export const TitleSub = styled.div<{ onClick?: () => void }>`
  ${flex({ gap: 16, isColumn: true })};

  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  & > p:first-of-type {
    ${text({ size: 24, height: 34, weight: 800, color: grayscaleAsh })};
  }
  & > p:last-of-type {
    ${text({ size: 17, height: 22, weight: 400, color: grayscaleLabel })};
  }
`;

export const TitleContent = styled.div`
  position: relative;
`;

export const Card = styled.div<BaseCardPropsType>`
  ${grid({ columns: "1fr 1fr" })};
  ${margins({ p: "24" })};

  ${({ size }) =>
    mediaSizes({
      field: "grid-template-columns",
      values: [
        "",
        "200px 1fr",
        "250px 1fr",
        "280px 1fr",
        size === "ultra" ? `407px 1fr` : size === "little" ? "240px 1fr" : "360px 1fr",
      ],
    })};

  ${({ size }) => borders({ radius: size === "ultra" ? 24 : 16 })};
  ${({ active, isReadOnly }) => `opacity: ${active && !isReadOnly ? 1 : !active ? 0.65 : 0.85}`};

  background: ${({ isReadOnly }) => (isReadOnly ? blackOpacity : white)};

  box-shadow: ${shadow};
`;

export const Price = styled.div<{ lineText: boolean }>`
  ${flex({ isColumn: true })};
  h4 {
    ${text({ size: 13, height: 17, weight: 400, color: grayscaleLabel })};
  }

  p {
    ${({ lineText }) =>
      text({
        size: 32,
        height: 38,
        weight: 700,
        color: lineText ? grayscalePlaceholder : black,
        line: lineText ? "center" : undefined,
      })};
  }
`;

export const PricesContainer = styled.div`
  ${flex({ gap: 8, horizontal: "evenly", vertical: "center" })}
  white-space: pre-line;
`;

export const PricesList = styled.div`
  ${flex({})}
`;
export const PricesContent = styled.div``;

export const Content = styled.div`
  ${flex({ gap: 16, isColumn: true, vertical: "center" })};
  ${size({ min: { w: 0 } })};
  ${margins({ p: "0 40" })};
`;

export const ContentBlock = styled.div``;

export const Hosted = styled.div`
  position: absolute;
  top: 17px;
  right: 0;
`;

export const Details = styled.p`
  ${text({ size: 16, height: 23, weight: 400, color: primaryDefault })};

  cursor: pointer;
`;

export const ReadOnlyInfo = styled.div`
  ${flex({ gap: 6, vertical: "center" })};

  path {
    fill: ${redDefault};
  }

  svg {
    ${size({ s: 24 })};
  }
`;

export const Rating = styled.div`
  ${size({ h: "fit", min: { w: 146 } })};
  ${borders({ radius: 12 })};
  ${margins({ m: "0 0 0 auto", p: "8 16" })};

  background: ${greenBackground};
`;

export const RatingScore = styled.span`
  ${text({ size: 17, height: 24, weight: 400, color: grayscaleAsh })};
`;

export const RatingReviews = styled.span`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleLabel })};
  ${margins({ m: "0 0 0 8" })};
`;

export const Description = styled.div<{ max: number }>`
  ${({ max }) => overflowLines(max)};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleLabel })};

  overflow-wrap: break-word;
  overflow: hidden;
`;
export const Features = styled.div`
  ${flex({ gap: 12 })};

  flex-wrap: wrap;
`;

export const FeatureLists = styled(Features)`
  ${flex({ isColumn: true })};
`;

export const FeatureList = styled(Features)`
  ${flex({ vertical: "center" })};

  flex-wrap: wrap;
`;

export const FeatureListItem = styled.p`
  ${size({ w: "max" })};
  ${borders({ radius: 12 })};
  ${text({ size: 15, height: 20, weight: 600, color: primaryDefault })};
  ${margins({ p: "8 12" })};

  background: ${primaryBackground};
`;
