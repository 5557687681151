/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ ИНИЦИАЛОВ
 *
 * -
 *
 * @param nameParts - части имени
 * @param nameParts.firstName - имя (string)
 * @param nameParts.middleName - отчество (string) (не обязательный)
 * @param nameParts.lastName - фамилия (string) (не обязательный)
 *
 */
import { NamePartsType } from "../api";

export const getUserInitials = (nameParts: NamePartsType) => {
  const { firstName, middleName, lastName } = nameParts;

  const firstLetter = firstName[0].toUpperCase();
  const secondLetter =
    middleName?.[0].toUpperCase() ?? lastName?.[0].toUpperCase() ?? firstName[1].toLowerCase();

  return `${firstLetter}${secondLetter}`;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ ПОЛНОГО ИМЕНИ (ФИО)
 *
 * -
 *
 * @param props - параметры
 * @param props.nameParts - части имени
 * @param props.nameParts.firstName - имя (string)
 * @param props.nameParts.middleName - отчество (string) (не обязательный)
 * @param props.nameParts.lastName - фамилия (string) (не обязательный)
 * @param props.withoutLastName - указание на то, что фамилию следует исключить (boolean) (не обязательный)
 * @param props.withoutMiddleName - указание на то, что отчество следует исключить (boolean) (не обязательный)
 *
 */

export const getFullName = (props: PropsType) => {
  const { nameParts, withoutLastName = false, withoutMiddleName = false } = props;

  if (!nameParts) return "";

  const { firstName, middleName, lastName } = nameParts;

  const formattedFirstName = firstName ? ` ${firstName}` : "";
  const formattedMiddleName = withoutMiddleName ? "" : middleName ? ` ${middleName}` : "";
  const formattedLastName = withoutLastName ? "" : lastName ?? "";

  return `${formattedLastName}${formattedFirstName}${formattedMiddleName}`.trimStart();
};

type PropsType = {
  nameParts: NamePartsType | null;
  withoutLastName?: boolean;
  withoutMiddleName?: boolean;
};
