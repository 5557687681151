import { DateRange } from "@mui/lab";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable, useAtomValue } from "jotai/utils";
import { applicationsApi, ApplicationType, EntitiesType, fetchPlans, SortType } from "../../api";
import { statusIds } from "../../constants/statusIds";
import * as filters from "../../pages/ApplicationLifecyclePages/Applications/Applications/hooks/useFilters";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { getQuerySearchConditions } from "../../utils/getQuerySearchConditions";
import { formatDate, today } from "../../utils/workingWithDates";
import {
  applicationsTypeState,
  ApplicationsTypeType,
  currentDateInterval,
  currentUserAtom,
  pageState,
  quantityInOnePageState,
  registryPreviewState,
  searchQueryState,
  sortTypeState,
  statusesAllState,
  UserType,
} from "../index";

const { applicationsFiltersState } = filters;

// ------------------------------ ЗАЯВКИ

const applicationsAtom = atomWithQuery((get) => {
  const user = get(currentUserAtom);

  const applicationsType = get(applicationsTypeState);

  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);
  const dateInterval = get(currentDateInterval);
  const filters = get(applicationsFiltersState);
  const sort = get(sortTypeState);

  const args = { user, applicationsType, page, quantity, queryState, filters, sort, dateInterval };

  return applicationsQuery(args);
});

export const applicationsState = loadable(applicationsAtom);

const applicationsQuery = (props: PropsType) => {
  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "applicationsState"],
    queryFn: () => fetchAll(() => applicationsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountApplicationsAtom = atomWithQuery((get) => {
  const user = get(currentUserAtom);

  const applicationsType = get(applicationsTypeState);

  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);
  const dateInterval = get(currentDateInterval);
  const filters = get(applicationsFiltersState);
  const sort = get(sortTypeState);

  const args = { user, applicationsType, page, quantity, queryState, filters, sort, dateInterval };

  return totalCountApplicationsQuery(args);
});

export const totalCountApplicationsState = loadable(totalCountApplicationsAtom);

const totalCountApplicationsQuery = (props: PropsType) => {
  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "totalCountApplicationsState"],
    queryFn: () => fetchTotalCount(() => applicationsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ВСЕ ЗАЯВКИ

export const canDownloadApplicationsAtom = atom<boolean>(false);

export const allApplicationsQuery = (props: PropsType & { canDownloadApplications: boolean }) => {
  const { canDownloadApplications } = props;

  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "allApplicationsState"],
    queryFn: () => fetchAll(() => applicationsApi().getAll(args)),
    keepPreviousData: true,
    enabled: canDownloadApplications,
  };
};

// ------------------------------ ДАННЫЕ ДЛЯ ПРЕДВАРИТЕЛЬНОГО ПРОСМОТРА РЕЕСТРА

const applicationsRegistryPreviewAtom = atomWithQuery((get) => {
  const statuses = get(statusesAllState);
  const isActive = get(registryPreviewState);
  const sort = get(sortTypeState);

  const args = {
    filter: [{ property: "status", operator: "=", value: statusIds.osr_approved_completeness }],
    fetchPlan: fetchPlans.resort_Application.get,
    sort,
  };

  return {
    queryKey: [statuses, isActive, "applicationsRegistryPreviewState"],
    queryFn: () => (isActive ? fetchAll(() => applicationsApi().getAll(args)) : []),
    keepPreviousData: true,
  };
});

export const applicationsRegistryPreviewState = loadable(applicationsRegistryPreviewAtom);

export const applicationsRegistryPreviewData = atom<EntitiesType<ApplicationType> | undefined>(
  undefined
);

// ------------------------------ ВЫЧИСЛЕНИЕ АРГУМЕНТОВ

export const useApplicationsKeys = () => {
  const user = useAtomValue(currentUserAtom);

  const applicationsType = useAtomValue(applicationsTypeState);

  const page = useAtomValue(pageState);
  const quantity = useAtomValue(quantityInOnePageState);
  const queryState = useAtomValue(searchQueryState);
  const dateInterval = useAtomValue(currentDateInterval);
  const filters = useAtomValue(applicationsFiltersState);
  const sort = useAtomValue(sortTypeState);

  const args = { user, applicationsType, page, quantity, queryState, filters, sort, dateInterval };

  return getArgs(args).keys;
};

const getArgs = (props: PropsType) => {
  const { user, applicationsType, page, quantity } = props;
  const { queryState, filters, sort, dateInterval } = props;

  const startIndex = page && quantity ? page * quantity : undefined;
  const size = quantity || undefined;
  const query = queryState || undefined;
  const employeeId = user ? user.res.id : undefined;
  const status = filters.status || undefined;
  const sanatorium = filters.sanatorium || undefined;
  const since = filters.since ? formatDate({ date: filters.since, type: "forBackend" }) : undefined;
  const until = filters.until ? formatDate({ date: filters.until, type: "forBackend" }) : undefined;

  const startDate = formatDate({ date: dateInterval[0] ?? today, type: "forBackend" });
  const endDate = formatDate({ date: dateInterval[1] ?? today, type: "forBackend" });

  const isMyApplications = employeeId && applicationsType === "my";

  const needPeriod =
    applicationsType !== "my" ||
    (applicationsType === "my" &&
      (user?.roleModel.department || user?.roleModel.osr || user?.roleModel.osrCa));

  const filter = [
    ...(isMyApplications
      ? [
          {
            group: "OR",
            conditions: [
              { property: "applicant.id", operator: "=", value: employeeId },
              { property: "employee.id", operator: "=", value: employeeId },
            ],
          },
        ]
      : []),

    ...(status ? [{ property: "status", operator: "=", value: status }] : []),
    ...(sanatorium ? [{ property: "room.sanatorium", operator: "=", value: sanatorium }] : []),

    ...(since && needPeriod ? [{ property: "until", operator: ">=", value: since }] : []),
    ...(until && needPeriod ? [{ property: "until", operator: "<=", value: until }] : []),

    ...(startDate ? [{ property: "since", operator: ">=", value: startDate }] : []),
    ...(endDate ? [{ property: "until", operator: "<=", value: endDate }] : []),

    ...(query
      ? [
          getQuerySearchConditions(query, [
            "employee.firstName",
            "employee.middleName",
            "employee.lastName",
          ]),
        ]
      : []),
  ];

  return {
    keys: [
      startIndex,
      size,
      query,
      applicationsType,
      employeeId,
      status,
      sanatorium,
      startDate,
      endDate,
    ],
    args: { startIndex, size, startDate, endDate, filter, sort },
  };
};

type PropsType = {
  user?: UserType;
  applicationsType: ApplicationsTypeType | "";
  page?: number;
  quantity?: number;
  queryState: string;
  filters: filters.FiltersType;
  sort: SortType;
  dateInterval: DateRange<Date>;
};
