import { AxiosResponse } from "axios";
import { AccommodationsType } from "./accommodations";
import { hrApi, hrServicesApi } from "./api";
import { orgEmployeesApi } from "./employees";
import { PhotosType } from "./photos";
import { entitiesBase } from "./request";
import { SanatoriumType } from "./sanatoriums";
import * as types from "./types";

// ------------------------------ НОМЕРА

export const roomsApi = <R>() => ({
  ...entitiesBase<RoomType, R, RoomsRequestType>(hrApi, "resort_Room"),

  getFiltered: (props: RoomsServiceRequestType): Promise<AxiosResponse<RoomsType>> =>
    hrServicesApi.post("/roomsFilterService/filter", props),
});

export const roomHistoryApi = {
  getAll: async (roomId: string): Promise<AxiosResponse<types.LogsWithEmployeesType>> => {
    const res = (await hrServicesApi.post("/roomLogService/getLogs", {
      roomId,
    })) as AxiosResponse<types.LogsType>;

    const { data, ...resWithoutData } = res;

    const dataWithEmployees = await Promise.all(
      data.map(async (datum) => {
        const employee = (await orgEmployeesApi().get(datum.personnelNumber)).data;

        return {
          ...datum,
          firstName: employee.firstName,
          middleName: employee.middleName,
          lastName: employee.lastName,
          positionName: employee.position?.name ?? "—",
        };
      })
    );

    return {
      ...resWithoutData,
      data: dataWithEmployees,
    };
  },
};

// ------------------------------ ТИПЫ - НОМЕРА

// ----- передаваемые значения

export type RoomsRequestType = types.PaginationType & {
  sanatoriumId?: string;
};

export type RoomsServiceRequestType = {
  roomFilterDto: types.PeriodType & {
    // todo: пока не реализовано на бэке
    // page: {
    //   priceSort: string;
    //   itemsPerPage?: number;
    //   page?: number;
    // };
    sort: SortTypeType;
    sanatorium: types.IdType;
    isRehab?: string | boolean;
    members: {
      kinship: string;
      age?: number;
      isNotOwn?: boolean;
      isSocialGroup?: boolean;
    }[];
  };
};

// ----- получаемые данные

export type RoomsType = RoomWithAccommodationType[];

export type RoomWithAccommodationType = {
  room: RoomType;
  accommodations: FilteredRoomAccommodationType[];
  sanatorium: SanatoriumType;
  fullPrice?: number;
  discountPrice?: number;
  engagedAdditional?: number;
  engagedPlaces?: number;
  withoutAccommodation?: number;
  withoutAccommodationAge?: number;
  isReadOnly?: boolean;
};

export type FilteredRoomAccommodationType = {
  roomType: types.AccommodationTypeType;
  category: types.AccommodationCategoryType;
  withAccommodation: boolean;
  fullPrice: number;
  discountPrice: number;
  member: {
    kinship: types.RoomMemberTypeType;
    age?: number;
    isNotOwn?: boolean;
  };
};

export type RoomType = types.IdType & {
  name: string;
  rooms: number;
  places: number;
  additional: number;
  accommodations: AccommodationsType;
  vip: boolean;
  containsRehab: boolean;
  description: string;
  photos: PhotosType;
};

export type SortTypeType = "ASC" | "DESC";
