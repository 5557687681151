import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EntityRequestBaseType, sanatoriumsApi, sanatoriumsServiceApi } from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { pageState, quantityInOnePageState, searchQueryState } from "../index";

export const confirmModalSuccessState = atom<boolean>(false);

// ------------------------------ ТЕКУЩИЙ САНАТОРИЙ

export const sanatoriumIdState = atom<string | undefined>(undefined);

// ------------------------------ РЕДАКТИРОВАНИЕ ИЛИ ДОБАВЛЕНИЕ

export const isEditSanatorium = atom<boolean>(false);

// ------------------------------ МЕНЯЛИСЬ ЛИ САНАТОРИИ

export const sanatoriumsChangedState = atom<boolean>(false);

// ------------------------------ ДАННЫЕ

const sanatoriumsAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);
  const sanatoriumsChanged = get(sanatoriumsChangedState);

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query, sanatoriumsChanged };

  return sanatoriumsQuery(args);
});

export const sanatoriumsState = loadable(sanatoriumsAtom);

const sanatoriumsQuery = (props: PropsType) => {
  const { startIndex, size, query, sanatoriumsChanged } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, sanatoriumsChanged, "sanatoriumsState"],
    queryFn: () => fetchAll(() => sanatoriumsApi().getAll(args)),
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountSanatoriumsAtom = atomWithQuery((get) => {
  const queryState = get(searchQueryState);

  const query = queryState || undefined;

  const args = { query };

  return totalCountSanatoriumsQuery(args);
});

export const totalCountSanatoriumsState = loadable(totalCountSanatoriumsAtom);

const totalCountSanatoriumsQuery = (props: { query?: string }) => {
  const { query } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex: 0, size: 1, filter };

  return {
    queryKey: [query, "totalCountSanatoriumsState"],
    queryFn: () => fetchTotalCount(() => sanatoriumsApi().getAll(args)),
  };
};

// ------------------------------ КОНКРЕТНЫЙ САНАТОРИЙ

const sanatoriumAtom = atomWithQuery((get) => {
  const id = get(sanatoriumIdState);

  return sanatoriumQuery(id);
});

export const sanatoriumState = loadable(sanatoriumAtom);

const sanatoriumQuery = (id?: string) => ({
  queryKey: [id, "sanatoriumState"],
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  queryFn: () => sanatoriumsServiceApi.get(id!),
  enabled: !!id,
});

type PropsType = EntityRequestBaseType & {
  sanatoriumsChanged: boolean;
};
