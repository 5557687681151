import { Avatar, Badge } from "@digitalatom/ui";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { menuIsExpandState } from "../../../atoms";
import { useNotification } from "../../../components/Notifications/useNotification";
import { Bell, PopoverModal, ProfileCard } from "../../../uiKit";
import { Container, Notifications, TotalCountWrapper } from "./Profile.styles";
import { ProfileMenu } from "./ProfileMenu";
import { useProfile } from "./useProfile";

export const Profile: FC<PropsType> = (props) => {
  const { baseUrl } = props;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  const methods = useProfile();
  const { fullName, initials, userIcon, role, canViewProfile, canViewNotifications } = methods;
  const { profileMenu, openProfileMenu, closeProfileMenu, profileMenuIsOpen } = methods;

  const { totalCountOfNew } = useNotification();

  return (
    <Container onlyIcon={!menuIsExpand} onClick={openProfileMenu}>
      {menuIsExpand ? (
        <>
          <ProfileCard fullName={fullName} initials={initials} role={role} />

          {canViewNotifications && (
            <Notifications>
              <TotalCountWrapper>
                {!!totalCountOfNew && (
                  <Badge text={totalCountOfNew} size="s" design="withStroke" type="accent" />
                )}
              </TotalCountWrapper>

              <Bell />
            </Notifications>
          )}
        </>
      ) : (
        <Avatar
          photo={userIcon}
          initials={initials}
          notificationsQuantity={totalCountOfNew}
          own
          disabled
        />
      )}

      <PopoverModal
        id="profileMenu"
        isOpen={profileMenuIsOpen}
        element={profileMenu}
        onClose={closeProfileMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <ProfileMenu
          notificationsQuantity={totalCountOfNew}
          canViewNotifications={canViewNotifications}
          canViewProfile={canViewProfile}
          onClose={closeProfileMenu}
          baseUrl={baseUrl}
        />
      </PopoverModal>
    </Container>
  );
};

type PropsType = {
  baseUrl?: string;
};
