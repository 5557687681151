import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import * as api from "../../api";
import { fetchById } from "../../utils/fetchData";

const { roomsApi, sanatoriumsServiceApi } = api;

// ---------------------------- ID

export const detailIdAtom = atom<DetailIdType>(null);

// ---------------------------- ДАННЫЕ

const detailInfoAtom = atomWithQuery((get) => {
  const detailId = get(detailIdAtom);

  return detailInfoQuery(detailId);
});

export const detailInfoState = loadable(detailInfoAtom);

const detailInfoQuery = (detailId: DetailIdType) => {
  return {
    queryKey: [detailId?.id, detailId?.type, "detailInfoState"],
    queryFn: (): Promise<api.RoomType | api.SanatoriumWithDetailRatingType> =>
      detailId?.type === "room"
        ? fetchById<api.RoomType>(() => roomsApi().get(detailId?.id ?? ""))
        : sanatoriumsServiceApi.get(detailId?.id ?? ""),
    enabled: !!detailId,
  };
};

export type DetailIdType = null | { id: string; type: "room" | "sanatorium" };
