import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { applicationsApi } from "../../api";
import { fetchById } from "../../utils/fetchData";
import { applicationIdState } from "../index";

// ------------------------------ ЗАЯВКА

const applicationAtom = atomWithQuery((get) => {
  const id = get(applicationIdState);

  return {
    queryKey: [id, "applicationState"],
    queryFn: () => fetchById(() => applicationsApi().get(id ?? "")),
    enabled: !!id,
  };
});

export const applicationState = loadable(applicationAtom);
