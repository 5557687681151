import { useAtom } from "jotai";
import React from "react";
import * as Yup from "yup";
import { BooleanType } from "../../../../../../../api";
import {
  sanatoriumsFiltersState,
  vacationersState,
  VacationersType,
} from "../../../../../../../atoms";
import { containRehabOptions } from "../../../../../../../constants/options";
import { RadioInput } from "../../../../../../../uiKit";
import { getVacationersCountText } from "../../../../../../../utils/getVacationersCountText";
import { NumberTitleInput } from "../NumberTitleInput/NumberTitleInput";
import { ChildrenInput } from "../SelectCategory/ChildrenInput/ChildrenInput";
import { SelectCategory } from "../SelectCategory/SelectCategory";

export const useForms = () => {
  // ------------------------------ АТОМЫ

  const [vacationers, setVacationers] = useAtom(vacationersState);
  const [filters, setFilters] = useAtom(sanatoriumsFiltersState);

  // ------------------------------ КОНСТАНТЫ

  const formName = "vacationersForm";

  // ------------------------------ НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValue = { ...vacationers, containsRehab: filters.isRehab as BooleanType };

  // ------------------------------ ПОЛЯ

  const formFields = (
    <>
      <NumberTitleInput
        name="adults.length"
        title="Взрослые"
        description="от 18 лет"
        minValue={1}
        maxValue={30}
      />
      <RadioInput name="containsRehab" options={containRehabOptions} />
      <SelectCategory name="adults" />
      <NumberTitleInput
        name="children.length"
        title="Дети"
        description="от 0 до 18 лет"
        maxValue={30}
      />
      <ChildrenInput name="children" />
    </>
  );

  // ------------------------------ ВАЛИДАЦИЯ ПОЛЕЙ

  const validationSchema = Yup.object().shape({
    adults: Yup.array().of(Yup.string().required("Укажите тип взрослого")),
    containsRehab: Yup.string().matches(/(true|false)/, { excludeEmptyString: true }),
    children: Yup.array().of(
      Yup.object()
        .shape({
          age: Yup.number().required("Укажите возраст ребенка"),
          isNotOwn: Yup.boolean(),
        })
        .required("Укажите возраст ребенка")
    ),
  });

  // ------------------------------ ДЕЙСТВИЯ

  const onSubmit = (values: VacationersType) => {
    const { adults, children } = values;

    const filterText = getVacationersCountText({
      adultsCount: adults.length,
      childrenCount: children.length,
    });

    setVacationers({ ...values, filterText });
    setFilters({ ...filters, isRehab: values.containsRehab });
  };

  return {
    initialValue,
    formName,
    formFields,
    validationSchema,

    onSubmit,
  };
};
