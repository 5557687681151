import { hrApi } from "./api";
import { MediumOtherType, SanatoriumAspectType } from "./dictionaries";
import { EmployeeType } from "./employees";
import { entitiesBase } from "./request";
import { SanatoriumType } from "./sanatoriums";
import { EntityRequestBaseType, IdType } from "./types";

// ------------------------------ РЕЙТИНГ

// todo: точно нужно?

export const ratingApi = <R>() =>
  entitiesBase<RatingType, R, EntityRequestBaseType>(hrApi, "resort_Rating");

// ------------------------------ ТИПЫ - РЕЙТИНГ

export type RatingType = IdType & {
  sanatorium: SanatoriumType;
  employee: EmployeeType;
  overall: number;
  overallReason: string;
  effectiveness: MediumOtherType;
  effectivenessReason: string;
  aspects: SanatoriumAspectType[];
  improvements: MediumOtherType[];
  improvementOther: string;
  recommendation: number;
  recommendationReason: string;
};

// получаемые данные

export type RatingsType = RatingType[];
