import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { workingConditionsClassesApi, WorkingConditionsClassType } from "../../api";
import { fetchAll } from "../../utils/fetchData";

export const workingConditionsClassesModalDataState = atom<WorkingConditionsClassType | undefined>(
  undefined
);

// ------------------------------ ДАННЫЕ

export const workingConditionsClassesState = atomWithQuery(() => ({
  queryKey: "workingConditionsClassesState",
  queryFn: () => fetchAll(() => workingConditionsClassesApi().getAll()),
}));
