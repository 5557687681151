import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { MediumOtherType, patientOutcomesApi } from "../../api";
import { fetchAll } from "../../utils/fetchData";

export const patientOutcomesModalDataState = atom<MediumOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const patientOutcomesState = atomWithQuery(() => ({
  queryKey: "patientOutcomesState",
  queryFn: () => fetchAll(() => patientOutcomesApi().getAll()),
}));
