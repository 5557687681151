import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { documentTypesApi, EntityRequestBaseType, MediumOtherType } from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { pageState, quantityInOnePageState, searchQueryState } from "../index";

export const documentTypesModalDataState = atom<MediumOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const documentTypesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return documentTypesQuery(args);
});

export const documentTypesState = loadable(documentTypesAtom);

const documentTypesQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "documentTypesState"],
    queryFn: () => fetchAll(() => documentTypesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountDocumentTypesAtom = atomWithQuery((get) => {
  const queryState = get(searchQueryState);

  const query = queryState || undefined;

  const args = { query };

  return totalCountDocumentTypesQuery(args);
});

export const totalCountDocumentTypesState = loadable(totalCountDocumentTypesAtom);

const totalCountDocumentTypesQuery = (props: { query?: string }) => {
  const { query } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex: 0, size: 1, filter };

  return {
    queryKey: [query, "totalCountDocumentTypesState"],
    queryFn: () => fetchTotalCount(() => documentTypesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const documentTypesAllState = atomWithQuery(() => ({
  queryKey: ["documentTypesAllState"],
  queryFn: () => fetchAll(() => documentTypesApi().getAll()),
}));
