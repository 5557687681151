import { Badge } from "@digitalatom/ui";
import React, { FC, MouseEvent, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notifications } from "../../../components";
import { USER_PERSONAL_INFO } from "../../../constants/routes";
import { Loader } from "../../Loader";
import { ProfileMenuButton, ProfileMenuButtons } from "./Profile.styles";

export const ProfileMenu: FC<PropsType> = (props) => {
  const { notificationsQuantity, baseUrl, canViewNotifications, canViewProfile, onClose } = props;

  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);

  const navigate = useNavigate();

  const logoutRoute = `http://${baseUrl}/c/portal/logout`;

  const logout = (evt: MouseEvent<HTMLElement>) => {
    window.location.href = logoutRoute;
    onClose(evt);
  };

  return (
    <>
      <ProfileMenuButtons>
        {canViewNotifications && (
          <ProfileMenuButton
            onClick={() => {
              setNotificationsIsOpen(true);
            }}
          >
            <p>Уведомления</p>

            {!!notificationsQuantity && (
              <Badge text={notificationsQuantity} size="s" design="withStroke" type="accent" />
            )}
          </ProfileMenuButton>
        )}

        {canViewProfile && (
          <ProfileMenuButton
            onClick={(evt) => {
              navigate(USER_PERSONAL_INFO);
              onClose(evt);
            }}
          >
            <p>Профиль</p>
          </ProfileMenuButton>
        )}

        <ProfileMenuButton onClick={logout}>
          <p>Выйти</p>
        </ProfileMenuButton>
      </ProfileMenuButtons>

      {canViewNotifications && (
        <Suspense fallback={<Loader />}>
          <Notifications
            open={notificationsIsOpen}
            onClose={() => setNotificationsIsOpen(false)}
            closeProfileMenu={onClose}
          />
        </Suspense>
      )}
    </>
  );
};

type PropsType = {
  notificationsQuantity?: number;
  canViewNotifications: boolean;
  canViewProfile: boolean;
  onClose: (evt: MouseEvent<HTMLElement>) => void;
  baseUrl?: string;
};
