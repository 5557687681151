import { useAtom } from "jotai";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import {
  modalPageState,
  modalQuantityState,
  notificationsState,
  totalCountNewNotificationsState,
  totalCountNotificationsState,
} from "../../atoms";

export const useNotification = () => {
  // ------------------------------ АТОМЫ

  const loadableData = useAtomValue(notificationsState);
  const totalCountData = useAtomValue(totalCountNotificationsState);
  const totalCountNewData = useAtomValue(totalCountNewNotificationsState);

  const setPage = useUpdateAtom(modalPageState);
  const [quantity, setQuantity] = useAtom(modalQuantityState);

  // ------------------------------ ДАННЫЕ

  const notifications = loadableData.state === "hasData" ? loadableData.data : [];

  const totalCount = totalCountData.state === "hasData" ? totalCountData.data : 0;
  const totalCountOfNew = totalCountNewData.state === "hasData" ? totalCountNewData.data : 0;

  const needPagination = {
    totalCount,
    setPage,
    quantity,
    setQuantity,
  };

  return {
    notifications,
    totalCount,
    totalCountOfNew,
    needPagination,
  };
};
