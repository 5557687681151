import { AxiosInstance, AxiosResponse } from "axios";
import { checkKey } from "../utils/checkKey";
import { fetchPlans, FetchPlanType } from "./fetchPlans";
import { sorting } from "./sorting";
import { IdType, SortType } from "./types";

/**
 *
 * ------------------------------------------------------------------------------------------
 * КАК ЭТО НАЗВАТЬ?
 *
 * -
 *
 * @param instance
 * @param entityName - имя эндпоинта
 * @param DATA - получаемый тип
 * @param VOBJ - отправляемый тип
 * @param PGAO - отправляемые параметры для getAll
 *
 */

export const entitiesBase = <DATA, VOBJ, PGAO>(
  instance: AxiosInstance,
  entityName: EntityNameType
) => {
  const fetchPlan = checkKey(fetchPlans, entityName) ? fetchPlans[entityName] : undefined;
  const sortForEntityName = checkKey(sorting, entityName) ? sorting[entityName] : undefined;

  return {
    getAll: async (
      props?: AllPropType<PGAO>
    ): Promise<AxiosResponse<EntitiesResponseType<DATA>>> => {
      const startIndex = props?.startIndex;
      const size = props?.size;
      const needFilter = !!props?.filter?.length;
      const sort = sortForEntityName ? sortForEntityName : props?.sort;

      const currentFetchPlan =
        props?.fetchPlan ?? (typeof fetchPlan === "string" ? fetchPlan : fetchPlan?.getAll);

      const params = {
        params: {
          ...(needFilter ? { filter: JSON.stringify({ conditions: props.filter }) } : {}),
          ...(sort ? { sort } : {}),
          ...(startIndex || startIndex === 0 ? { offset: startIndex } : {}),
          ...(size || size === 0 ? { limit: size } : {}),

          ...(currentFetchPlan ? { fetchPlan: currentFetchPlan } : {}),

          returnCount: true,
        },
      };

      const res = instance.get(`/${entityName}${needFilter ? "/search" : ""}`, params);

      const items = (await res).data;
      const totalCount = Number((await res).headers["x-total-count"]);

      return { ...res, data: { items, totalCount } };
    },

    get: (id: string): Promise<AxiosResponse<DATA>> => {
      const params = {
        params: {
          ...(fetchPlan
            ? { fetchPlan: typeof fetchPlan === "string" ? fetchPlan : fetchPlan.get }
            : {}),
        },
      };

      return instance.get(`/${entityName}/${id}`, params);
    },

    create: (props: EntityCreateType<VOBJ>): Promise<AxiosResponse<IdType>> => {
      const { data } = props;
      return instance.post(`/${entityName}`, data);
    },

    update: (props: EntityUpdateType<VOBJ>): Promise<AxiosResponse<VOBJ>> => {
      const { id, data } = props;
      return instance.put(`/${entityName}${id !== undefined ? `/${id}` : ""}`, data);
    },

    delete: (id: string): Promise<AxiosResponse<DATA, DATA>> => {
      return instance.delete(`/${entityName}/${id}`);
    },
  };
};

type AllPropType<PGAO> = PGAO & {
  startIndex?: number;
  size?: number;
  filter?: ConditionsSearchType;
  sort?: SortType;
  fetchPlan?: FetchPlanType;
};

export type ConditionsSearchType = ConditionSearchType[];

export type ConditionSearchType = {
  group?: "OR" | "AND" | string;
  conditions?: ConditionsSearchType;
  property?: string;
  operator?:
    | "="
    | "<>"
    | ">"
    | ">="
    | "<"
    | "<="
    | "startsWith"
    | "endsWith"
    | "contains"
    | "doesNotContain"
    | string;
  value?: boolean | string | number | Date;
};

export type EntityCreateType<T> = {
  data: T;
};

export type EntityUpdateType<T> = IdType & {
  data: T;
};

export type EntitiesResponseType<T> = { items: EntitiesType<T>; totalCount: number };

export type EntitiesType<T> = T[];

export type EntityNameType =
  | "employees"
  | "resort_Employee"
  | "resort_Sanatorium"
  | "sanatoriumsFilterService"
  | "sanatoriumsLogService"
  | "roomsLogService"
  | "resort_Room"
  | "resort_Accommodation"
  | "resort_Price"
  | "roomsFilterService"
  | "resort_Photo"
  | "resort_FamilyMember"
  | "resort_Document"
  | "resort_Country"
  | "resort_Region"
  | "resort_Treatment"
  | "resort_MedicalProfile"
  | "resort_Service"
  | "resort_ConditionClass"
  | "resort_HarmfulFactor"
  | "resort_MedicalProgram"
  | "resort_Aspect"
  | "resort_AspectGroup"
  | "resort_Improvement"
  | "resort_Effectiveness"
  | "resort_Status"
  | "resort_DocumentType"
  | "resort_RefusalReason"
  | "resort_Application"
  | "resort_Registry"
  | "resort_CalculatePriceSettings"
  | "resort_VouchersFrequencyCalculationRule"
  | "resort_Contact"
  | "resort_Rating"
  | "resort_GroupRatingOverridden"
  | "resort_BusinessProcessNotification"
  | "resort_DocumentsForPassing";
