import { AxiosResponse } from "axios";
import { ApplicationsTypeType } from "../atoms";
import { hrApi, hrServicesApi } from "./api";
import { ContactType } from "./contacts";
import { BigOtherType, SanatoriumAspectType, StatusType } from "./dictionaries";
import { EmployeeType, orgEmployeesApi, ResortEmployeeType } from "./employees";
import { FamilyMemberType } from "./familyMembers";
import { fetchPlans } from "./fetchPlans";
import { LinkedDocumentsType } from "./file";
import { GetTasksPropsType, lifecycleApi, TasksType, TaskType } from "./lifecycle";
import { RatingType } from "./rating";
import { HistoryStatusType, RegistryType } from "./registries";
import { entitiesBase, EntityCreateType, EntityUpdateType } from "./request";
import { RoomType } from "./rooms";
import { SanatoriumType } from "./sanatoriums";
import * as types from "./types";
import { IdType } from "./types";

// ------------------------------ ЗАЯВКИ

export const applicationsApi = <A>() => ({
  ...entitiesBase<Exclude<ApplicationType, "members" | "documents">, A, ApplicationRequestType>(
    hrApi,
    "resort_Application"
  ),

  getFiltered: (
    props: FilteredApplicationRequestType
  ): Promise<AxiosResponse<ApplicationsResponseType>> =>
    hrServicesApi.post(`/applicationService/getFiltered`, props),
  getAllFiltered: async (
    props: FilteredApplicationRequestType
  ): Promise<AxiosResponse<ApplicationsResponseType>> => {
    const { size: _, ...propsWithoutSize } = props;

    const totalCount = (await hrServicesApi.post(`/applicationService/getFiltered`, props)).data
      .totalCount;

    const res = await hrServicesApi.post(`/applicationService/getFiltered`, {
      size: totalCount,
      ...propsWithoutSize,
    });

    return res;
  },
  get: async (id: string): Promise<AxiosResponse<ApplicationWithTaskType>> => {
    const fetchPlan = fetchPlans["resort_Application"];
    const params = { params: { fetchPlan: fetchPlan.get } };

    const res = (await hrApi.get(
      `/resort_Application/${id}`,
      params
    )) as AxiosResponse<ApplicationType>;

    const { data, ...resWithoutData } = res;

    const process = "APPLICATION";
    const applicationNumber = data.number;

    const getTaskPromise = (
      fetchPromise: (props: GetTasksPropsType) => Promise<{ data: { items: TasksType } }>
    ) => fetchPromise({ process, applicationNumber }).then(({ data }) => data?.items?.[0]);

    let [myGroupTask, myTask, myCompletedTask]: (TaskType | undefined)[] = [
      undefined,
      undefined,
      undefined,
    ];

    myGroupTask = await getTaskPromise(lifecycleApi.getMyGroupTasks);
    if (!myGroupTask) myTask = await getTaskPromise(lifecycleApi.getMyTasks);
    if (!myTask) myCompletedTask = await getTaskPromise(lifecycleApi.getMyCompletedTasks);

    const task = myGroupTask || myTask || myCompletedTask;

    let actualCompletedProcessVariables;

    if (!task) {
      try {
        actualCompletedProcessVariables = (
          await lifecycleApi.getProcessVariablesByKey({
            key: applicationNumber,
          })
        ).data;
      } catch {
        actualCompletedProcessVariables = undefined;
      }
    }

    const type = myGroupTask
      ? "inbox"
      : myTask
      ? "in-progress"
      : myCompletedTask
      ? "processed"
      : undefined;

    const employee = (await orgEmployeesApi().get(data.employee.personnelNumber)).data;

    return {
      ...resWithoutData,
      data: {
        ...data,
        ...(task ? { task: { type, ...task } } : {}),
        actualCompletedProcessVariables,
        employee: { ...data.employee, ...employee },
      },
    };
  },

  create: (props: EntityCreateType<A>): Promise<AxiosResponse<types.IdType>> => {
    const { data } = props;
    return hrServicesApi.post("/applicationService/create", data);
  },
  update: (props: EntityUpdateType<A>): Promise<AxiosResponse<A>> => {
    const { id, data } = props;
    return hrApi.put(`/resort_Application/${id}`, data);
  },

  // downloadApplications: (id: string) =>
  //   hrApiRest.get("/downloadApplicationController/download", { params: { id } }),
});

export const documentsForPassingApi = <A>() =>
  entitiesBase<DocumentsForPassingType, A, undefined>(hrApi, "resort_DocumentsForPassing");

// ------------------------------ ТИПЫ - ЗАЯВКИ

export type MemberRoomType = types.IdType & {
  member: FamilyMemberType;
  application: ApplicationType;
  room: RoomType;
  type: types.AccommodationTypeType;
};

export type AspectRatingType = types.IdType & {
  aspect: SanatoriumAspectType;
  rating: RatingType;
  value: number;
};

export type ApplicationType = types.IdType &
  types.PeriodType & {
    applicant: ResortEmployeeType;
    employee: EmployeeType;
    room: RoomType & { sanatorium: SanatoriumType };
    // rooms: RoomWithAccommodationType[];
    members: MemberRoomType[];
    number: number;
    price: number;
    programs: BigOtherType[];
    documents: LinkedDocumentsType;
    documentsForPassing?: DocumentsForPassingType;
    frequencyStatus: FrequencyStatus;
    status: StatusType;
    statusHistory: HistoryStatusType[];
    registry: RegistryType;
    rating: RatingType;
    comment: string;
    createdDate: types.BigBackendDateType;
    lastDateCompletedApplication?: types.BigBackendDateType;
    lastModifiedDate: types.BigBackendDateType;
    contacts?: ContactType;
  };

export type DocumentsForPassingType = IdType & {
  authorFullName: string;
  authorRole: string;
  documents: LinkedDocumentsType;
};

export type ApplicationWithTaskType = ApplicationType & {
  task?: TaskType & { type?: ApplicationsTypeType };
  actualCompletedProcessVariables?: TaskType["processVariables"];
}; // todo: дополнить данными задачи

// ----- передаваемые значения

type ApplicationRequestType = Partial<types.EntityRequestBaseType> & {
  employeeId?: string;
  registryId?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
};

type FilteredApplicationRequestType = types.PaginationType & {
  registry: string;
  filter: Partial<types.PeriodType> & {
    query?: string;
    department?: string;
    containsRehab?: types.BooleanType;
    medicalProgramIds?: { uuid: string }[];
    sanatoriumId?: string;
    corrected?: boolean;
  };
};

// ----- получаемые значения

export type ApplicationsResponseType = { items: ApplicationsType; totalCount: number };

export type ApplicationsType = ApplicationType[];

export type FrequencyStatus = "VIOLATED" | "COMPLIED" | "NOT_CALCULATED";

export type FrequencyStatusForTasks = "violated" | "complied" | "not_calculated";
