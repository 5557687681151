import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fileApi, FileLinkType } from "../api";

const getFileBlob = async (link: string) => {
  const res = await fileApi.download({ fileRef: link });
  return new Blob([res.data], { type: res.headers["content-type"] });
};

export const downloadFile = async (link?: FileLinkType, name?: string) => {
  if (!link || !name) return;

  const blob = await getFileBlob(link);
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  URL.revokeObjectURL(url);
};

export const downloadFileFromByteArray = async (
  downloadFunction: () => Promise<AxiosResponse>,
  defaultName?: string
) => {
  const { data, headers } = await downloadFunction();
  const fileName = headers["content-disposition"].split("''")[1] || defaultName || "report.docx";
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadURL;
  link.download = fileName;
  link.click();
  document.body.removeChild(link);
};

export const useQueryPhoto = (document: FileLinkType | undefined) =>
  useQuery(["file", document], async () => (!document ? null : getFileBlob(document)), {
    staleTime: 60000,
    cacheTime: 60000,
  }).data;
