import { TextInput } from "@digitalatom/ui";
import saveAs from "file-saver";
import { useField, useFormikContext } from "formik";
import React, { FC, useState } from "react";
import { DocumentsForFormType } from "../../api";
import { FileLink } from "../../components";
import { FormSubTitle } from "../../styles";
import { fileSizeConversion } from "../../utils/fileSizeConversion";
import { OptionsType } from "../../utils/getOptions";
import { EditButton, OkButton, TrashButton } from "../Buttons";
import { Book } from "../Icons";
import { SelectAutocompleteInput } from "../SelectAutocompleteInput";
import * as elements from "./DocumentsInput.styles";
import { Buttons } from "./DocumentsInput.styles";

export const DocumentList: FC<ListPropsType> = (props) => {
  const { options, optionsLabel, isEdit = false, name: fieldName = "files" } = props;

  const [editing, setEditing] = useState<number>();

  const { Container, Text, Title, Description, FileBlock, DocumentIconAndName, LoadBar } = elements;

  const [{ value: documents }] = useField<DocumentsForFormType | []>(fieldName);
  const { setFieldValue } = useFormikContext<DocumentsForFormType | []>();

  const handleRemove = async (i: number) => {
    setFieldValue(
      fieldName,
      documents.filter((_, j) => i !== j)
    );
  };

  return (
    <>
      {isEdit ? null : <FormSubTitle>Загруженные документы</FormSubTitle>}

      {documents.map(({ id, file, name, comment }, i) => {
        const parameters = JSON.parse(comment);
        const accept = parameters.accept;
        const size = parameters.size;

        return (
          <FileBlock key={i}>
            <Container status="wasFile" little>
              <DocumentIconAndName>
                <LoadBar status="loaded">
                  <Book />
                  {comment && accept ? accept : ""}
                </LoadBar>

                {editing === i ? (
                  <TextInput name={`${fieldName}.${i}.name`} />
                ) : (
                  <Text>
                    {id !== "" && typeof file === "string" ? (
                      <Title status="wasFile">
                        <FileLink file={file} name={`${name}${accept}`} />
                      </Title>
                    ) : id === "" && file && typeof file !== "string" ? (
                      <Title
                        status="wasFile"
                        onClick={() =>
                          comment && accept ? saveAs(file, `${name}${accept}`) : undefined
                        }
                        needDownload
                      >
                        {name}
                      </Title>
                    ) : null}

                    <Description status="wasFile">
                      {comment && size ? `${fileSizeConversion(size)}` : ""}
                    </Description>
                  </Text>
                )}
              </DocumentIconAndName>

              <Buttons>
                {isEdit || editing === i ? null : <TrashButton onClick={() => handleRemove(i)} />}

                {editing === i ? (
                  <OkButton
                    onClick={() => {
                      setEditing(undefined);
                      file instanceof File &&
                        setFieldValue(
                          `${fieldName}.${i}.file`,
                          new File([file], `${name}.${file.name.split(".").pop()}`)
                        );
                    }}
                  />
                ) : (
                  <EditButton onClick={() => setEditing(i)} />
                )}
              </Buttons>
            </Container>

            {options && (
              <SelectAutocompleteInput
                name={`${fieldName}.${i}.type`}
                options={options}
                label={optionsLabel ?? ""}
              />
            )}
          </FileBlock>
        );
      })}
    </>
  );
};

export type ListPropsType = {
  options?: OptionsType;
  optionsLabel?: string;
  isEdit?: boolean;
  name?: string;
};
