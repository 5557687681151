import React, { DragEventHandler, FC, RefObject } from "react";
import { preventDefault } from "../../utils/preventDefault";
import { Export } from "../Icons";
import * as elements from "./DocumentsInput.styles";

export const InputEmpty: FC<PropsType> = (props) => {
  const { label, description, accept, element, addFile } = props;

  const { Container, Title, Description, Text, LoadBarAndText, LoadBar } = elements;

  const handleDragOver: DragEventHandler<HTMLElement> = (evt) => preventDefault(evt);

  const handleDragLeave: DragEventHandler = (evt) => preventDefault(evt);

  const handleDrop: DragEventHandler = (evt) => {
    preventDefault(evt);

    const files = evt.dataTransfer.files;

    addFile(files);
    if (element.current?.files) element.current.files = files;
  };

  return (
    <Container
      status="empty"
      onClick={() => element.current?.click()}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <LoadBarAndText status="empty">
        <LoadBar status="empty">
          <Export />
        </LoadBar>
        <Text>
          <Title fullWidth={typeof label !== "string"} status="empty">
            {label}
          </Title>
          {description !== false ? (
            <Description status="empty">
              {description ? description : "Подходят файлы в формате 24x24px "}
              {accept.join(" ")}
            </Description>
          ) : null}
        </Text>
      </LoadBarAndText>
    </Container>
  );
};

type PropsType = {
  label: string | JSX.Element;
  description?: string | false;
  accept: string[];
  addFile: (file: FileList) => void;
  element: RefObject<HTMLInputElement>;
};
