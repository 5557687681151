import styled from "@emotion/styled";
import { Modal, ModalProps } from "@mui/material";
import { borders, colors, flex, margins, shadow, size, text } from "../../styles";

const { white, black, grayscaleIcons, grayscaleHoverBackground } = colors;

export const Overlay = styled(Modal)`
  .MuiBackdrop-root {
    background: ${grayscaleIcons};
    backdrop-filter: blur(10px);
    opacity: 0.3 !important;
  }
` as (props: ModalProps) => JSX.Element;

export const Container = styled.div`
  ${flex({ isColumn: true })};
  ${size({ h: `calc(100% - 32px)`, w: 700 })};
  ${borders({ radius: 16 })};
  ${margins({ m: "16 0 0 60" })};

  position: relative;
  background: ${grayscaleHoverBackground};
  box-shadow: ${shadow};

  :focus-visible {
    outline: none;
  }
`;

export const Header = styled.div<{ visibleSearch: boolean }>`
  ${({ visibleSearch }) => margins({ p: visibleSearch ? "16 24 1 24" : "16 24" })};

  background: ${white};
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
`;

export const TitleAndButtons = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${size({ h: 40 })};
`;

export const Title = styled.h3`
  ${text({ size: 20, height: 26, weight: 800, color: black })};
`;

export const Buttons = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
`;

export const NotificationsListWrapper = styled.div<{ hasPagination: boolean }>`
  ${size({ h: "100%" })};
  ${borders({ radius: 16 })};
  ${({ hasPagination }) => margins({ m: hasPagination ? "16 16 106 16" : "16" })};

  background: ${white};
  overflow: overlay;
  scrollbar-width: none;
`;

export const NotificationsList = styled.div`
  ${flex({ isColumn: true })};
  ${size({ h: "fit", max: { h: `calc(100% - 104px)` } })};
  ${margins({ m: "0" })};
`;
