import styled from "@emotion/styled";
import { DownloadStatusType } from "../../api";
import { borders, button, colors, flex, margins, overflowOneLine, size, text } from "../../styles";

const { transparent, grayscaleInput, grayscaleBeautifulBlack, grayscaleLabel } = colors;
const { grayscaleBackground, primaryActiveElement, primaryDefault } = colors;
const { redDefault, greenDefault } = colors;

export const Container = styled.div<{ status: DownloadStatusType; little?: boolean }>`
  ${({ status }) =>
    status === "empty"
      ? flex({ totalCenter: true })
      : flex({ horizontal: "evenly", vertical: "center" })};
  ${({ little }) => size({ h: little ? 72 : 157, w: "100%" })};
  ${({ status }) =>
    borders({
      width: 2,
      ...(status === "empty" ? { style: "dashed" } : {}),
      color: status === "empty" ? primaryActiveElement : grayscaleInput,
      radius: 16,
    })};
  ${margins({ m: "0 0 16 0", p: "16" })};

  position: relative;
  background: ${transparent};

  outline: none;
  cursor: ${({ status }) => (status === "empty" ? "pointer" : "default")};

  :hover {
    ${({ status }) =>
      borders({
        width: 2,
        ...(status === "empty" ? { style: "dashed" } : {}),
        color: status === "empty" ? primaryDefault : grayscaleInput,
      })};
  }
`;

export const LoadBarAndText = styled.div<{ status: DownloadStatusType }>`
  ${({ status }) =>
    status === "empty"
      ? flex({ isColumn: true, horizontal: "center" })
      : flex({ gap: 12, vertical: "center" })};
  ${({ status }) => size({ w: status === "empty" ? "100%" : `calc(100% - 52px)` })};

  overflow: hidden;
`;

export const LoadBar = styled.div<{ status: DownloadStatusType }>`
  ${flex({ isColumn: true, totalCenter: true })};
  ${size({ s: 40 })};
  ${({ status }) =>
    borders({
      ...(status === "empty" || status === "loaded"
        ? { none: true }
        : {
            width: 3,
            color:
              status === "error"
                ? redDefault
                : status === "success"
                ? greenDefault
                : grayscaleBackground,
          }),
      totalRadius: true,
    })};
  ${text({ size: 10, height: 13, weight: 900 })};
  ${margins({ m: "0" })};
`;

export const Progress = styled.div`
  ${size({ s: 40 })};
  ${margins({ m: "0" })};

  position: relative;

  .MuiCircularProgress-root {
    color: ${primaryDefault};
  }
`;

export const Percent = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 40 })};
  ${borders({ width: 3, color: grayscaleBackground, totalRadius: true })};
  ${text({ size: 10, height: 10, weight: 900, color: grayscaleBeautifulBlack })};

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const DocumentIconAndName = styled.div`
  ${flex({ gap: 12, vertical: "center" })};
  ${size({ w: "100%" })};

  div:last-child {
    ${size({ w: "85%" })};
  }
`;

export const Text = styled.div`
  ${flex({ isColumn: true, horizontal: "left" })};
  ${overflowOneLine};
`;

export const Title = styled.div<TitlePropsType>`
  ${({ fullWidth }) =>
    fullWidth ? undefined : size({ w: `calc(472px - 40px * 3 - 12px - 16px * 2)` })};
  ${({ status }) => {
    const notFile = status === "progress" || status === "error" || status === "success";
    const color = notFile ? grayscaleLabel : primaryDefault;
    return text({ size: 15, height: 20, weight: 700, color: color });
  }};
  ${overflowOneLine};

  :hover {
    ${({ needDownload }) => needDownload && "cursor: pointer"};
  }
`;

export const Description = styled.p<{ status: DownloadStatusType }>`
  ${({ status }) => {
    const color = status === "error" ? redDefault : grayscaleLabel;

    return text({ size: 13, height: 23, weight: 400, color: color });
  }};
`;

export const Icon = styled.div`
  ${flex({ totalCenter: true })};
  ${size({ s: 40 })};
  ${margins({ m: "0" })};
`;

export const Button = styled.button`
  ${button};
  ${size({ s: 40 })};
  ${margins({ m: "0 0 0 12" })};
`;

export const Input = styled.input`
  display: none;
  position: absolute;
  top: 24px;
`;

export const FileBlock = styled.div`
  ${margins({ m: "0 0 40 0" })};

  position: relative;
`;

export const Buttons = styled.div`
  ${flex({ gap: 2, horizontal: "center", vertical: "center" })};
`;

type TitlePropsType = {
  status: DownloadStatusType;
  needDownload?: boolean;
  fullWidth?: boolean;
};
