import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { calculationSettingsApi, CalculationSettingsType } from "../../api";
import { fetchAll } from "../../utils/fetchData";

export const calculationSettingsModalDataState = atom<CalculationSettingsType | undefined>(
  undefined
);

// ------------------------------ ДАННЫЕ

export const calculationSettingsState = atomWithQuery(() => ({
  queryKey: "calculationSettingsState",
  queryFn: () => fetchAll(() => calculationSettingsApi().getAll()),
}));
