import { SelectChangeEvent } from "@mui/material";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { modalPageState, modalQuantityState, pageState, quantityInOnePageState } from "../../atoms";
import { OpenListButton } from "../Buttons";

export const usePagination = (totalCount: number, inModal: boolean) => {
  const setPage = useUpdateAtom(pageState);
  const setModalPage = useUpdateAtom(modalPageState);
  const [quantity, setQuantity] = useAtom(quantityInOnePageState);
  const [modalQuantity, setModalQuantity] = useAtom(modalQuantityState);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page") ?? "1");

  const value =
    (inModal ? modalQuantity : quantity) > 100 ||
    Number(inModal ? modalQuantity : quantity) === Number(totalCount)
      ? "все"
      : inModal
      ? modalQuantity
      : quantity;

  const handleChange = (_: ChangeEvent<unknown>, value: number) =>
    setSearchParams({ page: String(value) });

  const handleChangeQuantity = (evt: SelectChangeEvent<unknown>) => {
    const number = evt.target.value === "все" ? totalCount : Number(evt.target.value);

    inModal ? setModalQuantity(number) : setQuantity(number);
    inModal ? setModalPage(0) : setPage(0);
  };

  const count = Math.ceil(Number(totalCount) / (inModal ? modalQuantity : quantity));

  const hasMoreOnePage = !!totalCount && count !== 1;

  // ------------------------------ < ИНПУТ ВЫБОРА КОЛИЧЕСТВО СТРОК НА СТРАНИЦЕ

  const [isOpenList, setIsOpenList] = useState(false);

  const handleOpenList = () => setIsOpenList((prevState) => !prevState);

  const openListIcon = () => <OpenListButton isOpen={isOpenList} />;

  // ------------------------------ ИНПУТ ВЫБОРА КОЛИЧЕСТВО СТРОК НА СТРАНИЦЕ >

  // ------------------------------ < ВЫЧИСЛЕНИЕ ШИРИНЫ ОКНА ДЛЯ ТОГО, ЧТОБ ПРИПОДНЯТЬ ПАГИНАЦИЮ ПРИ ПОЯВЛЕНИИ СКРОЛЛА

  const [isWidthLessThanMinimum, setIsWidthLessThanMinimum] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const widthMeter = () => setIsWidthLessThanMinimum(window.innerWidth <= 1024);
    window.addEventListener("resize", widthMeter);

    return () => window.removeEventListener("resize", widthMeter);
  }, []);

  // ------------------------------ ВЫЧИСЛЕНИЕ ШИРИНЫ ОКНА ДЛЯ ТОГО, ЧТОБ ПРИПОДНЯТЬ ПАГИНАЦИЮ ПРИ ПОЯВЛЕНИИ СКРОЛЛА >

  // ------------------------------ < УСТАНОВКА НОМЕРА СТРАНИЦЫ ИЗ АДРЕСНОЙ СТРОКИ

  useEffect(() => {
    inModal ? setModalPage(page - 1) : setPage(page - 1);
    count < page && setSearchParams({ page: String(count) });
  }, [page, count]);

  // ------------------------------ УСТАНОВКА НОМЕРА СТРАНИЦЫ ИЗ АДРЕСНОЙ СТРОКИ >

  return {
    value,

    page,
    handleChange,
    handleChangeQuantity,

    count,

    hasMoreOnePage,

    isOpenList,
    handleOpenList,
    openListIcon,

    isWidthLessThanMinimum,
  };
};
