import React, { FC, RefObject } from "react";
import { ArrowRound, ExclamationMark } from "../Icons";
import * as elements from "./DocumentsInput.styles";

export const InputError: FC<PropsType> = (props) => {
  const { element, fileNames, error, handleRemove, name = "files" } = props;

  const { Container, Title, Description, Text, Button, LoadBarAndText, LoadBar } = elements;

  return (
    <Container status="error" as="div">
      <LoadBarAndText status="error">
        <LoadBar status="error">
          <ExclamationMark />
        </LoadBar>
        <Text>
          <Title status="error">{fileNames(element)}</Title>
          <Description status="error">{error}</Description>
        </Text>
      </LoadBarAndText>

      <Button onClick={() => handleRemove(name, element)}>
        <ArrowRound />
      </Button>
    </Container>
  );
};

type PropsType = {
  element: RefObject<HTMLInputElement>;
  fileNames: (element?: RefObject<HTMLInputElement>) => string;
  error: string;
  handleRemove: (name: string, ref: RefObject<HTMLInputElement>) => void;
  name?: string;
};
