import { Formik, FormikValues } from "formik";
import { useUpdateAtom } from "jotai/utils";
import React, { useState } from "react";
import { addFormState } from "../../atoms";
import { FormContainer } from "../../styles";
import { convertFileToBase64 } from "../../utils/convertFileToBase64";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { useQuery } from "../../utils/useQuery";
import { validateChanges } from "../../utils/validateChanges";
import { ErrorText } from "../ErrorBoundary/ErrorBoundary.styles";
import { MethodsType } from "../Page";
import { RightModal, RightModalActions, RightModalBody } from "../RightModal";
import { FormButtons } from "./FormButtons";

export const Form = <DATUM, OBJ, FLDS extends FormikValues, VOBJ, FLTRS>(
  props: PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>
) => {
  const { methods, id, isEdit = false, withoutModal = false } = props;

  const { addFormName, editFormName, editFormTitle, addFormTitle, validationSchema } = methods;
  const { initialValuesAddForm, initialValuesEditForm, fields, modalData, setModalData } = methods;
  const { api, queryKey, onSubmit, valuesObject } = methods;

  const setAddFormIsOpen = useUpdateAtom(addFormState);

  const [error, setError] = useState();

  const create = useQuery<VOBJ>().useCreate({ api, queryKey });
  const update = useQuery<VOBJ>().useUpdate({ api, queryKey });

  // useEffect(() => {
  //   setError(modalData && isEdit ? getErrorMessage(update.error) : getErrorMessage(create.error));
  // }, [create.error, update.error]);
  // ошибка стабильно определяется без useQuery?
  // можно isLoading определить без useQuery?

  const isLoading = modalData && isEdit ? update.isLoading : create.isLoading;

  const handleClose =
    modalData && setModalData && isEdit
      ? () => setModalData(undefined)
      : () => setAddFormIsOpen(false);

  if (
    !api ||
    !queryKey ||
    !fields ||
    !initialValuesEditForm ||
    !initialValuesAddForm ||
    !editFormTitle ||
    !addFormTitle ||
    !editFormName ||
    !addFormName
  )
    return null;

  const initialValuesObject =
    modalData && isEdit ? initialValuesEditForm(modalData) : initialValuesAddForm;

  const formName = modalData && isEdit ? editFormName : addFormName;

  const form = (
    <Formik
      initialValues={initialValuesObject}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (!validateChanges({ initialValues: initialValuesObject, values })) return;

        for (const key in values) {
          if (values[key] === "") {
            values[key] = null as FLDS[Extract<keyof FLDS, string>];
          }
        }

        if (values.icon?.type === "image/svg+xml") {
          const icon = await convertFileToBase64(values.icon);
          values = { ...values, icon: icon };
        }

        try {
          onSubmit
            ? await onSubmit(values, isEdit, id)
            : valuesObject
            ? !modalData || !isEdit
              ? await create.mutateAsync({ data: valuesObject(values) })
              : id
              ? await update.mutateAsync({ data: valuesObject(values), id })
              : undefined
            : undefined;

          handleClose();
        } catch (err) {
          setError(getErrorMessage(err));
          console.error("err:", getErrorMessage(err));
        }
      }}
    >
      {({ handleSubmit, isValid, dirty }) => (
        <FormContainer onSubmit={handleSubmit} id={formName}>
          <RightModalBody>
            {fields ? (typeof fields !== "function" ? fields : fields(isEdit)) : null}
          </RightModalBody>

          {!!error && <ErrorText>{error}</ErrorText>}

          <RightModalActions>
            <FormButtons
              formName={formName}
              onClose={handleClose}
              saveOrAdd={modalData && isEdit ? "save" : "add"}
              disabled={isLoading || !isValid || !dirty}
            />
          </RightModalActions>
        </FormContainer>
      )}
    </Formik>
  );

  return withoutModal ? (
    form
  ) : (
    <RightModal
      title={modalData && isEdit ? editFormTitle : addFormTitle}
      isOpen
      onClose={handleClose}
      needBlur
    >
      {form}
    </RightModal>
  );
};

type PropsType<DATUM, OBJ, FLDS extends FormikValues, VOBJ, FLTRS> = {
  methods: Pick<
    MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>,
    | "addFormName"
    | "editFormName"
    | "editFormTitle"
    | "addFormTitle"
    | "validationSchema"
    | "initialValuesAddForm"
    | "initialValuesEditForm"
    | "fields"
    | "modalData"
    | "setModalData"
    | "api"
    | "queryKey"
    | "onSubmit"
    | "valuesObject"
  >;
  id?: string;
  isEdit?: boolean;
  withoutModal?: boolean;
};
