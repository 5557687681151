import { AxiosResponse } from "axios";
import { hrApi, hrServicesApi } from "./api";
import { BigOtherType, OtherWithIconType, RegionType, TreatmentMethodType } from "./dictionaries";
import { orgEmployeesApi } from "./employees";
import { PhotosType } from "./photos";
import { RatingType } from "./rating";
import { entitiesBase } from "./request";
import { RoomType } from "./rooms";
import {
  BackendDateType,
  IdType,
  LogsType,
  LogsWithEmployeesType,
  PaginationType,
  PeriodType,
} from "./types";

// ------------------------------ САНАТОРИИ

export const sanatoriumsApi = <S>() =>
  entitiesBase<SanatoriumType, S, SanatoriumsRequestType>(hrApi, "resort_Sanatorium");

export const sanatoriumsFilterServiceApi = {
  getAll: (
    props: SanatoriumsFilterServiceRequestType
  ): Promise<AxiosResponse<SanatoriumsFilterResponseType>> =>
    hrServicesApi.post("/sanatoriumsFilterService/filter", props),
};

export const sanatoriumsServiceApi = {
  getAll: (props: SanatoriumsServiceRequestType): Promise<SanatoriumType[]> =>
    hrServicesApi.post("/sanatoriumService/getSanatoriums", props),
  get: async (id: string): Promise<SanatoriumWithDetailRatingType> => {
    const sanatoriumData = (await sanatoriumsApi().get(id)).data; // todo: избавиться от лишних запросов, как будет готов бэк
    const { ratingGroups, ratingCount, ratingOverallOverridden } = (
      await hrServicesApi.post("/sanatoriumService/getSanatorium", { id })
    ).data;

    return { ...sanatoriumData, ratingGroups, ratingCount, ratingOverallOverridden };
  },
};

export const sanatoriumHistoryApi = {
  getAll: async (sanatoriumId: string): Promise<AxiosResponse<LogsWithEmployeesType>> => {
    const res = (await hrServicesApi.post("/sanatoriumLogService/getLogs", {
      sanatoriumId,
    })) as AxiosResponse<LogsType>;

    const { data, ...resWithoutData } = res;

    const dataWithEmployees = await Promise.all(
      data.map(async (datum) => {
        const employee = (await orgEmployeesApi().get(datum.personnelNumber)).data;

        return {
          ...datum,
          firstName: employee.firstName,
          middleName: employee.middleName,
          lastName: employee.lastName,
          positionName: employee.position?.name ?? "—",
        };
      })
    );

    return {
      ...resWithoutData,
      data: dataWithEmployees,
    };
  },
};

// ------------------------------ ТИПЫ - САНАТОРИИ

export type SanatoriumType = IdType & {
  active: boolean;
  rooms: RoomType[];
  address: string;
  containsRehab: boolean;
  profiles: BigOtherType[];
  rating?: RatingType[];
  description: string;
  services: OtherWithIconType[];
  photos: PhotosType;
  overallRatingOverridden?: number;
  treatments: TreatmentMethodType[];
  name: string;
  region: RegionType;
  price?: number;
};

export type SanatoriumWithDetailRatingType = SanatoriumType & {
  ratingGroups?: RatingGroupType[];
  ratingCount: number;
  ratingOverallOverridden: boolean;
};

export type RatingGroupType = IdType & {
  // это id aspectGroup
  overriddenId: string; // это id самой сущность
  name: string;
  overridden: boolean;
  value: number;
};

// ----- передаваемые значения

export type SanatoriumHistoryRequestType = PaginationType & {
  sanatoriumId?: string;
};

export type SanatoriumsRequestType = PaginationType & {
  year?: number;
  mainDepartmentCodes?: string;
  status?: string;
  export?: string;
  disabilityId?: string;
  diseaseClassId?: string;
  startDateOfDiseasePeriod?: BackendDateType;
  endDateOfDiseasePeriod?: BackendDateType;
  order?: string;
  city?: string;
  startDate?: string;
  endDate?: string;
  heath?: string;
  profiles?: string;
  rating?: string;
};

export type SanatoriumsServiceRequestType = {
  sanatoriumServiceDto: {
    isActive?: boolean;
    offset?: number;
    limit?: number;
  };
};

export type SanatoriumsFilterServiceRequestType = {
  sanatoriumFilterDto: PeriodType & {
    page: {
      priceSort: string;
      itemsPerPage?: number;
      page?: number;
    };
    sanatoriums?: IdType[];
    profiles?: IdType[];
    regions?: IdType[];
    countries?: IdType[];
    rating?: number;
    isRehab?: string;
  };
};

// ----- получаемые значения

export type SanatoriumsFilterResponseType = {
  sanatoriums: SanatoriumsType;
  itemsPerPage: number;
  page: number;
  size: number;
};

export type SanatoriumsType = SanatoriumType[];
