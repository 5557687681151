import { AxiosResponse } from "axios";
import React, { FC, useEffect, useState } from "react";
import { timerForQueries } from "../../utils/timerForQueries";
import { OnDeleteReturnType } from "../Page/Page";
import { Container } from "./Container";
import { Error } from "./Error";
import { Question } from "./Question";
import { Success } from "./Success";
import { Warning } from "./Warning";

// ------ компонент приведён в соответствие с общим макетом для окон подтверждения
//        если в каком-то не специфическом макете (для окон подтверждения) встретится отличный от имеющихся вариант,
//        надо уточнить у дизайнера, насколько критично такое исполнение, надо ли расширять компонент или приводить мает в соответствие

/**
 *
 * ОКНО ПОДТВЕРЖДЕНИЯ
 * Возможные сценарии:
 * 1. вопрос → левая кнопка (нет) → окно закрывается
 * 2. вопрос → правая кнопка (да) → окно закрывается и действие совершается
 * 3. вопрос → правая кнопка (да) → подтверждение успеха → левая кнопка → окно закрывается и действие совершается
 * 4. вопрос → правая кнопка (да) → подтверждение успеха → правая кнопка → окно закрывается и действие совершается
 * 5. вопрос → правая кнопка (да) → подтверждение успеха → кнопка "хорошо" (единственная) → окно закрывается
 * 6. вопрос → правая кнопка (да) → ошибка → кнопка "понятно" (единственная) → окно закрывается
 * 7. запрос на удаление → левая кнопка (нет) → окно закрывается
 * 8. запрос на удаление → правая кнопка (да) → подтверждение успеха → окно само закрывается через 2000мс
 * 9. запрос на удаление → правая кнопка (да) → ошибка → кнопка "понятно" (единственная) → окно закрывается
 * 10. подтверждение успеха → левая кнопка → окно закрывается и действие совершается
 * 11. подтверждение успеха → правая кнопка → окно закрывается и действие совершается
 * 12. подтверждение успеха → кнопка "хорошо" (единственная) → окно закрывается
 * 13. подтверждение ошибки → кнопка "понятно" (единственная) → окно закрывается
 *
 * для практического изучения компонента можно добавлять варианты отображения в ExampleConfirmModal и просматривать их в /styles/confirm-modal
 *
 * -
 *
 * @param question - параметры окна с вопросом (1 - 9) | не обязательно
 * @param question.text - вопрос (1 - 9) | обязательно
 * @param question.description - комментарий к вопросу (1 - 9) | не обязательно
 * @param question.outlinedButton - параметры левой кнопки (1, 7) | не обязательно
 * @param question.outlinedButton.onClick - действие, совершаемое при клике по левой кнопке (1) | не обязательно
 * @param question.coloredButton - параметры правой кнопки (2 - 6, 8 - 9) | обязательно
 * @param question.coloredButton.onClick - действие, совершаемое при клике по правой кнопке (2 - 6) | либо onClick и text, либо onDelete
 * @param question.coloredButton.text - текст для правой кнопки (2 - 6) | либо onClick и text, либо onDelete
 * @param question.coloredButton.onDelete - действие удаления (8 - 9) | либо onClick и text, либо onDelete
 * @param success - параметры окна с сообщением об успешном выполнении операции (3 - 5, 8, 10 - 12) | не обязательно
 * @param success.text - сообщение об успехе (3 - 5, 8, 10 - 12) | обязательно
 * @param success.description - комментарий к сообщению об успехе (3 - 5, 8, 10 - 12) | не обязательно
 * @param success.outlinedButton - параметры левой кнопки (3, 10) | не обязательно
 * @param success.outlinedButton.onClick - действие, совершаемое при клике по левой кнопке (3, 10) | обязательно
 * @param success.outlinedButton.text - текст для левой кнопки (3, 10) | обязательно
 * @param success.coloredButton - параметры правой кнопки (4 - 6, 11 - 12) | обязательно
 * @param success.coloredButton.onClick - действие, совершаемое при клике по правой кнопке (4 - 6, 11 - 12) | обязательно
 * @param success.coloredButton.text - текст для правой кнопки (4 - 6, 11 - 12) | не обязательно
 * @param isOpen - булево значение, переключающее открытость-закрытость окна (1 - 12) | обязательно
 * @param onClose - функция закрывающая окно (1 - 12) | обязательно
 * @param error - параметры окна с сообщением об ошибке (13) | не обязательно
 * @param error.text - сообщение об ошибке (13) | обязательно
 *
 */

export const ConfirmModal: FC<ConfirmModalPropsType> = (props) => {
  const { question, success, warningText, onClose } = props;

  const initialStatus = question
    ? "onDelete" in question.coloredButton
      ? "deletionQuestion"
      : "question"
    : warningText
    ? "warning"
    : "success";

  const [status, setStatus] = useState<StatusType>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setStatus(initialStatus);
    return () => setStatus(initialStatus);
  }, []);

  const handleStatusChange = async (newStatus: StatusType) => {
    setStatus(newStatus);

    if (newStatus === "successfulDeleted" && !success?.coloredButton && !success?.outlinedButton) {
      await timerForQueries(3000);
      success?.onDeleteClose && success.onDeleteClose();
      onClose();
    }
  };

  const args = { ...props, onClose };

  if (!status) return null;

  return (
    <Container {...args}>
      {status === "question" || status === "deletionQuestion" ? (
        <Question {...args} setStatus={handleStatusChange} setError={setError} />
      ) : status === "success" || status === "successfulDeleted" ? (
        <Success {...args} status={status} />
      ) : status === "warning" ? (
        <Warning {...args} />
      ) : status === "error" || status === "deletionError" ? (
        <Error {...args} error={error} />
      ) : null}
    </Container>
  );
};

export type ConfirmModalPropsType = {
  question?: {
    text: string;
    description?: string;
    outlinedButton?: { onClick?: () => void; text?: string };
    coloredButton:
      | {
          onClick: (() => void) | ((values?: unknown) => Promise<AxiosResponse<unknown, unknown>>);
          text: string;
        }
      | { onDelete: () => OnDeleteReturnType<unknown> };
  };
  success?: {
    text: string;
    description?: string;
    outlinedButton?: { onClick: () => void; text: string };
    coloredButton?: { onClick: () => void; text?: string };
    onDeleteClose?: () => void;
  };
  warningText?: string;
  isOpen: boolean;
  onClose: () => void;
};

export type StatusType =
  | "question"
  | "success"
  | "warning"
  | "error"
  | "deletionQuestion"
  | "successfulDeleted"
  | "deletionError"
  | undefined;
