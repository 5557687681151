import React, { FC } from "react";
import { OptionsType } from "../../utils/getOptions";
import { DocumentList, ListPropsType } from "./DocumentList";
import { Input } from "./DocumentsInput.styles";
import { InputEmpty } from "./InputEmpty";
import { InputError } from "./InputError";
import { InputProgress } from "./InputProgress";
import { InputSuccess } from "./InputSuccess";
import { useDocumentsInput } from "./useDocumentsInput";

export const DocumentsInput: FC<PropsType> = (props) => {
  const { label, description, accept, maxSize, options } = props;
  const { optionsLabel, isEdit = false, name = "files" } = props;
  const { needDocumentsList = true, multiDocuments = true } = props;

  const methods = useDocumentsInput({ accept, maxSize, multiDocuments });

  const { error, progress, fileNames, filesSize, addFile, handleRemove, onChange } = methods;
  const { ref, status, documents } = methods;

  const args = { element: ref, progress, error, fileNames, filesSize, handleRemove, name };

  return (
    <>
      {isEdit ? null : status === "error" ? (
        <InputError {...args} />
      ) : status === "progress" ? (
        <InputProgress {...args} />
      ) : status === "success" ? (
        <InputSuccess />
      ) : !multiDocuments && documents.length === 1 ? null : (
        <InputEmpty
          label={label}
          description={description}
          accept={accept}
          element={ref}
          addFile={addFile}
        />
      )}

      {documents.length && needDocumentsList ? (
        <DocumentList options={options} optionsLabel={optionsLabel} isEdit={isEdit} name={name} />
      ) : null}

      <Input
        ref={ref}
        name={name}
        type="file"
        accept={accept.join(",")}
        onChange={onChange}
        multiple={multiDocuments}
      />
    </>
  );
};

type PropsType = ListPropsType & {
  label: string | JSX.Element;
  description?: string | false;
  accept: string[];
  maxSize?: number;
  options?: OptionsType;
  optionsLabel?: string;
  isEdit?: boolean;
  name?: string;
  needDocumentsList?: boolean;
  multiDocuments?: boolean;
};
