import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import React, { FC } from "react";
import { menuIsExpandState } from "../../../atoms";
import { Expand, Reduce } from "../../Icons";
import { openSubmenuState } from "../LeftMenu";
import { Hint, useHint } from "../components";
import { Container } from "./ExpandButton.styles";

export const ExpandButton: FC = () => {
  const [menuIsExpand, setMenuIsExpand] = useAtom(menuIsExpandState);
  const setOpenSubMenu = useUpdateAtom(openSubmenuState);

  const text = (expand: boolean) => (expand ? "Развернуть меню" : "Свернуть меню");

  const popoverHoverMethods = useHint("expandButton", text(!menuIsExpand));
  const { argsForHint, setPopoverHoverText } = popoverHoverMethods;

  const expandMenu = () => {
    setMenuIsExpand((prevState) => !prevState);
    setOpenSubMenu(undefined);
    setPopoverHoverText(text(menuIsExpand));
  };

  return (
    <Container onClick={expandMenu} {...argsForHint}>
      {menuIsExpand ? <Reduce /> : <Expand />}

      <Hint type="expandButton" popoverHoverMethods={popoverHoverMethods} />
    </Container>
  );
};
