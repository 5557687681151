import React from "react";
import { colors } from "../../styles";

export const Cross = ({ size = 14 }: PropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3001 0.700014C12.9135 0.313415 12.2867 0.313415 11.9001 0.700014L7.00011 5.60001L2.10011 0.700014C1.71351 0.313415 1.08671 0.313415 0.700106 0.700014C0.313506 1.08661 0.313506 1.71342 0.700106 2.10001L5.60011 7.00001L0.700106 11.9C0.313506 12.2866 0.313506 12.9134 0.700106 13.3C1.08671 13.6866 1.71351 13.6866 2.10011 13.3L7.00011 8.40001L11.9001 13.3C12.2867 13.6866 12.9135 13.6866 13.3001 13.3C13.6867 12.9134 13.6867 12.2866 13.3001 11.9L8.40011 7.00001L13.3001 2.10001C13.6867 1.71342 13.6867 1.08661 13.3001 0.700014Z"
      fill={colors.grayscaleLabel}
    />
  </svg>
);

type PropsType = {
  size?: number;
};
