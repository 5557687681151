import { add } from "date-fns";
import { Formik } from "formik";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { currentDateIntervalForSanatoriums, sanatoriumsFiltersState } from "../../../atoms";
import { FormButton, Loupe } from "../../../uiKit";
import { today } from "../../../utils/workingWithDates";
import { FormFilter } from "../SanatoriumListPage.styles";
import { useFilters } from "../hooks/useFilters";

export const PanelFilter: FC<PropsType> = (props) => {
  const { shortVersion = false, isAlternative } = props;

  const [filters, setFilters] = useAtom(sanatoriumsFiltersState);
  const dateInterval = useAtomValue(currentDateIntervalForSanatoriums);

  const { formName, validationSchemaFilters, fieldsPanelFilter } = useFilters(isAlternative);

  return (
    <Formik
      initialValues={filters}
      validationSchema={validationSchemaFilters}
      validateOnBlur
      onSubmit={() => undefined}
    >
      {({ handleSubmit, values, isValid }) => (
        <FormFilter id={formName} onSubmit={handleSubmit} shortVersion={shortVersion}>
          {fieldsPanelFilter(shortVersion)}

          <FormButton
            text="Найти"
            formName={formName}
            variant="text"
            icon={<Loupe />}
            onClick={() => {
              isValid && shortVersion
                ? setFilters({
                    ...filters,
                    startDate: dateInterval[0] ?? today,
                    endDate: dateInterval[1] ?? add(today, { weeks: 2 }),
                  })
                : setFilters({
                    ...values,
                    rating: filters.rating,
                    profiles: filters.profiles,
                    startDate: dateInterval[0] ?? today,
                    endDate: dateInterval[1] ?? add(today, { weeks: 2 }),
                  });
            }}
          />
        </FormFilter>
      )}
    </Formik>
  );
};

type PropsType = {
  shortVersion?: boolean;
  isAlternative?: boolean;
};
