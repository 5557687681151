import { useField } from "formik";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { selectedRoomsState } from "../../../../../../../atoms";
import * as options from "../../../../../../../constants/options";
import { SelectInput } from "../../../../../../../uiKit";
import { Container } from "./SelectCategory.styles";

export const SelectCategory: FC<PropsType> = (props) => {
  const { name } = props;

  const [{ value }] = useField<string[]>(name);

  const selectedRooms = useAtomValue(selectedRoomsState);

  const isEmployeeWasSelected = !!selectedRooms.find(({ vacationers }) =>
    vacationers.adults.includes(options.employeeOption.id)
  );
  const isSpouseWasSelected = !!selectedRooms.find(({ vacationers }) =>
    vacationers.adults.includes(options.spouseOption.id)
  );

  const {
    familyAdultMembersOptions: categoryMemberOptions,
    employeeOption,
    spouseOption,
  } = options;

  const getOptions = (optionId: string) =>
    categoryMemberOptions.filter(({ id }) => {
      if (
        optionId !== id &&
        ((isEmployeeWasSelected && id === options.employeeOption.id) ||
          (isSpouseWasSelected && id === options.spouseOption.id))
      )
        return false;

      if (optionId !== id && value.includes(id))
        return id !== employeeOption.id && id !== spouseOption.id;

      return true;
    });

  return (
    <Container>
      {Array.from(value).map((category, i) => (
        <SelectInput
          key={i}
          name={`${name}.${i}`}
          placeholder="Укажите тип взрослого"
          options={getOptions(category)}
          size={328}
          disabled={i === 0 && selectedRooms.length === 0}
        />
      ))}
    </Container>
  );
};

type PropsType = {
  name: string;
};
