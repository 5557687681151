import { DateRange } from "@mui/lab";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { registriesApi, RegistryType } from "../../api";
import { RoleModelType } from "../../constants/rights";
import { statusIds as statusIdsList } from "../../constants/statusIds";
import { fetchById } from "../../utils/fetchData";
import { startAndEndMethods, today } from "../../utils/workingWithDates";
import { currentUserAtom, searchQueryState } from "../index";

export const registriesModalDataState = atom<RegistryType | undefined>(undefined);

// ------------------------------ ФИЛЬТРЫ

export const registriesFiltersState = atom<FiltersType>({ status: [] });

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateIntervalForRegisters = atom<DateRange<Date>>([
  startAndEndMethods.start.year(today),
  startAndEndMethods.end.year(today),
]);

// ------------------------------ ДАННЫЕ

const registriesAtom = atomWithQuery((get) => {
  const dateInterval = get(currentDateIntervalForRegisters);
  const filters = get(registriesFiltersState);
  const query = get(searchQueryState);
  const { roleModel } = get(currentUserAtom);

  return registriesQuery({ dateInterval, filters, query, roleModel });
});

export const registriesState = loadable(registriesAtom);

const registriesQuery = (props: PropsType) => {
  const { args, keys } = processArgs(props);

  return {
    queryKey: keys,
    queryFn: () => fetchById(() => registriesApi().getRegistriesWithFilters(args)),
    keepPreviousData: true,
  };
};

const processArgs = (props: PropsType) => {
  const { filters, dateInterval, query, roleModel } = props;
  const { sk, operator } = roleModel;

  const statusIds = operator
    ? [statusIdsList.approved_registry]
    : sk
    ? [statusIdsList.approved_registry, statusIdsList.onApproval_registry]
    : filters.status || undefined;
  const organizationCode = filters.organization || undefined;
  const startDate = dateInterval[0] ?? today;
  const nameQuery = query || undefined;

  const args = {
    organizationCode,
    statusIds,
    year: startDate.getFullYear().toString(),
    nameQuery,
  };

  return {
    keys: [statusIds, organizationCode, startDate.toDateString(), "registriesAtom"],
    args,
  };
};

type PropsType = {
  dateInterval: DateRange<Date>;
  filters: FiltersType;
  query: string;
  roleModel: RoleModelType;
};

type FiltersType = {
  status: string[];
  organization?: string;
};
