import { startOfDay } from "date-fns";
import { atom, useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React from "react";
import * as Yup from "yup";
import { applicationsTypeState, currentUserAtom } from "../../../../../atoms";
import {
  emptyMessage,
  notJustSpaces,
  thisDateIsToCorrect,
  thisPeriodIsNotCorrect,
} from "../../../../../constants/forValidationSchemes";
import { withoutSpaces } from "../../../../../constants/regexs";
import { SelectAutocompleteInput, StartAndEndDatesInput } from "../../../../../uiKit";
import { useSanatoriumOptions, useStatusOptions } from "../../../../../utils/getOptions";
import { isDateObject } from "../../../../../utils/workingWithDates";
import { FilterFields } from "../Applications.styles";

export const applicationsAbsoluteInitialValues: FiltersType = {
  status: "",
  sanatorium: "",
  since: null,
  until: null,
};

export const applicationsFiltersState = atom<FiltersType>(applicationsAbsoluteInitialValues);

export const useFilters = () => {
  // ------------------------------ АТОМЫ

  const [filters, setFilters] = useAtom(applicationsFiltersState);
  const applicationsType = useAtomValue(applicationsTypeState);

  const { roleModel: roles } = useAtomValue(currentUserAtom);

  // ------------------------------ ХУКИ

  const statusOptions = useStatusOptions("APPLICATION");
  const sanatoriumsOptions = useSanatoriumOptions();

  // ------------------------------ КОНСТАНТЫ

  const filtersFormName = "filterForm";
  const filtersFormTitle = "Фильтры";

  const needPeriod =
    applicationsType !== "my" ||
    (applicationsType === "my" && (roles.department || roles.osr || roles.osrCa));

  const fieldsFilters = (
    <FilterFields>
      <SelectAutocompleteInput name="status" options={statusOptions} label="Статус" />

      <SelectAutocompleteInput name="sanatorium" options={sanatoriumsOptions} label="Санаторий" />

      {needPeriod && (
        <StartAndEndDatesInput
          label={{ start: "Период прохождения", stop: " " }}
          name={{ start: "since", stop: "until" }}
          merge
        />
      )}
    </FilterFields>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesFilters = {
    status: filters.status,
    sanatorium: filters.sanatorium,
    since: filters.since,
    until: filters.until,
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchemaFilters = Yup.object().shape({
    status: Yup.string().matches(withoutSpaces, notJustSpaces),
    sanatorium: Yup.string(),
    since: Yup.date()
      .nullable()
      .typeError(thisDateIsToCorrect)
      .test("period_error", thisPeriodIsNotCorrect, (_, { parent: { since, until } }) =>
        isDateObject(since) && isDateObject(until) ? startOfDay(since) <= startOfDay(until) : true
      ),
    until: Yup.date()
      .nullable()
      .typeError(thisDateIsToCorrect)
      .test("period_error", emptyMessage, (_, { parent: { since, until } }) =>
        isDateObject(since) && isDateObject(until) ? startOfDay(since) <= startOfDay(until) : true
      ),
  });

  return {
    filtersFormName,
    filtersFormTitle,

    fieldsFilters,

    filters,
    setFilters,
    absoluteInitialValues: applicationsAbsoluteInitialValues,

    initialValuesFilters,
    validationSchemaFilters,
  };
};

export type FiltersType = {
  status: string;
  sanatorium: "";
  since: Date | null;
  until: Date | null;
};
