import React, { FC, Fragment } from "react";
import { Square, ThreeDots } from "../Icons";
import * as elements from "./Skeleton.styles";

export const Skeleton: FC<PropsType> = (props) => {
  const { forElement } = props;

  const { Item, Row, SkeletonForWideTable, SkeletonForWideTableCard } = elements;
  const { SkeletonForCharts, SkeletonForPanel, SkeletonForTable, TableSimulation } = elements;
  const { SkeletonForWideTableDate, SkeletonForWideTableStatus, SkeletonForCard } = elements;

  return forElement === "table" ? (
    <TableSimulation>
      {Array.from({ length: 5 }, (_, i) => {
        const { grids, canEdit } = props;

        const columnsCount = grids.split(" ").length;

        return (
          <Row key={i} grids={grids}>
            {canEdit && (
              <Item>
                <Square />
              </Item>
            )}

            {Array.from({ length: canEdit ? columnsCount - 2 : columnsCount }, (_, i) => (
              <Fragment key={i}>
                <Item>
                  <SkeletonForTable animation="wave" />
                </Item>
              </Fragment>
            ))}

            {canEdit && (
              <Item>
                <ThreeDots />
              </Item>
            )}
          </Row>
        );
      })}
    </TableSimulation>
  ) : forElement === "wideTable" ? (
    <TableSimulation forWideTable>
      {Array.from({ length: 5 }, (_, i) => {
        const { grids } = props;

        return (
          <Row key={i} grids={grids}>
            <Item>
              <SkeletonForWideTable animation="wave" />
              <SkeletonForWideTable animation="wave" />
              <SkeletonForWideTable animation="wave" />
            </Item>
            <Item>
              <SkeletonForWideTableStatus animation="wave" />
            </Item>
            <Item>
              <SkeletonForWideTableDate animation="wave" />
            </Item>
            <Item>
              <SkeletonForWideTableCard animation="wave" />
            </Item>
          </Row>
        );
      })}
    </TableSimulation>
  ) : forElement === "card" ? (
    <>
      <SkeletonForCard animation="wave" />
      <SkeletonForCard animation="wave" />
      <SkeletonForCard animation="wave" />
    </>
  ) : forElement === "panel" ? (
    <SkeletonForPanel animation="wave" />
  ) : (
    <SkeletonForCharts animation="wave" />
  );
};

type PropsType =
  | {
      forElement: "table";
      grids: string;
      canEdit: boolean;
    }
  | {
      forElement: "wideTable";
      grids: string;
    }
  | {
      forElement: "panel" | "charts" | "card";
    };
