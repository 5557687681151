import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import * as api from "../api";
import { fetchAll } from "../utils/fetchData";

const { feedbackApi } = api;

// ------------------------------ ФИЛЬТРЫ

export const feedbackAbsoluteInitialValues: FiltersType = {
  organization: "",
  sanatorium: "",
};

export const feedbackFiltersState = atom<FiltersType>(feedbackAbsoluteInitialValues);

type FiltersType = {
  organization: string;
  sanatorium: string;
};

// ------------------------------ ДАННЫЕ

const feedbackAtom = atomWithQuery(() => feedbackQuery());

export const feedbackState = loadable(feedbackAtom);

const feedbackQuery = () => ({
  queryKey: ["feedbackState"],
  queryFn: () => fetchAll(() => feedbackApi().getAll()),
  keepPreviousData: true,
});
