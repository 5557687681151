import React, { FC, ReactNode } from "react";
import { SanatoriumType } from "../../../api";
import { Photo } from "../../Photo";
import { BaseCard } from "../BaseCard";
import { PriceBlock } from "../PriceBlock";
import { RatingBlock } from "../RatingBlock";
import { Content, PricesContainer } from "../RoomsSanatoriumCard.styles";
import { SanatoriumFeatureList } from "../SanatoriumFeatureList/SanatoriumFeatureList";
import { ServicesView } from "../ServicesView/ServicesView";
import { Title } from "../Title";

export const SanatoriumListCard: FC<PropsType> = (props) => {
  const { sanatorium, button } = props;

  const { photos, name, address, price, profiles, services } = sanatorium;

  const ratingGroup = "—";
  const ratingOverall = "0";

  const mainPhoto = photos?.find(({ isMain }) => isMain) ?? photos?.[0];

  return (
    <BaseCard>
      <Photo file={mainPhoto?.file} height={450} />
      <Content>
        <Title title={name} subTitle={address}>
          <RatingBlock allRating={ratingOverall} averageRating={ratingGroup} />
        </Title>

        <ServicesView services={services} />
        <SanatoriumFeatureList profiles={profiles} />
        <PricesContainer>
          <PriceBlock description="Цена за 1 день" price={price} />
          {button}
        </PricesContainer>
      </Content>
    </BaseCard>
  );
};

type PropsType = {
  sanatorium: SanatoriumType;
  button: ReactNode;
};
