import { useField, useFormikContext } from "formik";
import React, { FC } from "react";
import { useDebouncedCallback } from "use-debounce";
import { colors, Error } from "../../styles";
import { today } from "../../utils/workingWithDates";
import { Calendar as CalendarIcon } from "../Icons";
import * as elements from "./DateInput.styles";

export const DateInput: FC<PropsType> = (props) => {
  const { name, placeholder, label, minDate = null, maxDate = null } = props;
  const { disabled = false, color = colors.grayscaleInput } = props;

  const [{ value, onBlur }, { error, touched }, { setTouched }] = useField<Date>(name);
  const { setFieldValue, submitCount } = useFormikContext();

  const onChangeHandler = useDebouncedCallback((newValue: unknown) => {
    setTouched(true);
    setFieldValue(name, newValue, true);
  }, 200);

  const { ContainerCalendar, Label, Calendar } = elements;
  const { PaperProps, InputAndIcon, Input, Icon } = elements;

  const hasError = (touched || submitCount) && !!error;

  const defaultCalendarMonth =
    maxDate && maxDate < today ? maxDate : minDate && minDate > today ? minDate : undefined;

  return (
    <ContainerCalendar>
      {label ? <Label>{label}</Label> : null}

      <Calendar
        defaultCalendarMonth={defaultCalendarMonth}
        label={placeholder}
        value={value || null}
        minDate={minDate}
        maxDate={maxDate}
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        onChange={onChangeHandler}
        PaperProps={PaperProps}
        renderInput={(params) => (
          <InputAndIcon disabled={disabled}>
            <Input
              value={value}
              needlabel={+!!label}
              onBlur={onBlur}
              haserror={+hasError}
              {...params}
              name={name}
              autoComplete="off"
              backgroundcolor={color}
            />
            <Icon needlabel={+!!label}>
              <CalendarIcon />
            </Icon>
          </InputAndIcon>
        )}
        disabled={disabled}
      />

      {hasError ? <Error bottom={-14}>{error}</Error> : null}
    </ContainerCalendar>
  );
};

type PropsType = {
  name: string;
  placeholder: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  color?: string;
};
