import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { employeesApi } from "../../api";
import { fetchById } from "../../utils/fetchData";
import { applicationForFormAtom } from "../index";

export const employeePersonnelNumberState = atom<string | undefined>(undefined);

export const employeeInfoAtom = atomWithQuery((get) => {
  const personnelNumber = get(employeePersonnelNumberState);
  const application = get(applicationForFormAtom);

  const employeePersonnelNumber = application?.employee.personnelNumber ?? personnelNumber;

  return {
    queryKey: [employeePersonnelNumber, "employeeInfoState"],
    queryFn: () =>
      employeePersonnelNumber
        ? fetchById(() => employeesApi().get(employeePersonnelNumber))
        : undefined,
    enabled: !!employeePersonnelNumber,
  };
});
