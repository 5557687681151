import { getYear, startOfDay } from "date-fns";
import { atom, useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React from "react";
import * as Yup from "yup";
import { BooleanType } from "../../../../../api";
import { currentDateInterval } from "../../../../../atoms";
import {
  emptyMessage,
  thisDateIsToCorrect,
  thisPeriodIsNotCorrect,
} from "../../../../../constants/forValidationSchemes";
import { containRehabOptions } from "../../../../../constants/options";
import {
  CheckInput,
  SelectAutocompleteInput,
  SelectAutocompleteMultilineInput,
  StartAndEndDatesInput,
} from "../../../../../uiKit";
import {
  useMainDepartmentOptions,
  useSanatoriumOptions,
  useTypicalMedicalProgramOptions,
} from "../../../../../utils/getOptions";
import { isDateObject } from "../../../../../utils/workingWithDates";
import { FilterFields } from "../../../Applications/Applications/Applications.styles";

export const applicationsInRegistryAbsoluteInitialValues: FiltersType = {
  department: "",
  containsRehab: "",
  medicalPrograms: [],
  sanatorium: "",
  since: null,
  until: null,
  selectOnlyCorrected: false,
};

export const applicationsInRegistryFiltersState = atom<FiltersType>(
  applicationsInRegistryAbsoluteInitialValues
);

export const useFilters = (isApprovedRegistry: boolean) => {
  // ------------------------------ АТОМЫ

  const [filters, setFilters] = useAtom(applicationsInRegistryFiltersState);
  const dateInterval = useAtomValue(currentDateInterval);

  // ------------------------------ ХУКИ

  const sanatoriumsOptions = useSanatoriumOptions();
  const mainDepartmentOptions = useMainDepartmentOptions();
  const typicalMedicalProgramOptions = useTypicalMedicalProgramOptions();

  // ------------------------------ КОНСТАНТЫ

  const filtersFormName = "filterForm";
  const filtersFormTitle = "Фильтры";

  const fieldsFilters = (filtersValue: FiltersType) => (
    <FilterFields>
      <SelectAutocompleteInput
        name="department"
        options={mainDepartmentOptions}
        title="Подразделение"
      />

      <SelectAutocompleteInput name="containsRehab" options={containRehabOptions} title="РОМ/СКЛ" />

      {filtersValue.containsRehab === "true" ? (
        <SelectAutocompleteMultilineInput
          name="medicalPrograms"
          title="Название медицинской программы"
          options={typicalMedicalProgramOptions}
          placeholder="Выберите из списка"
          result="text"
        />
      ) : null}

      <SelectAutocompleteInput name="sanatorium" options={sanatoriumsOptions} title="Санаторий" />

      <StartAndEndDatesInput
        title="Период"
        name={{ start: "since", stop: "until" }}
        label={{ start: "С", stop: "По" }}
        placeholder={{ start: "ДД.ММ.ГГГГ", stop: "ДД.ММ.ГГГГ" }}
        minDate={dateInterval[0] ? dateInterval[0] : undefined}
        maxDate={dateInterval[1] ? dateInterval[1] : undefined}
      />

      {isApprovedRegistry && (
        <CheckInput
          name="selectOnlyCorrected"
          label="Выбрать откорректированные заявки"
          variant="filled"
          labelType="gray"
        />
      )}
    </FilterFields>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesFilters: FiltersType = filters;

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchemaFilters = Yup.object().shape({
    sanatorium: Yup.string(),
    since: Yup.date()
      .nullable()
      .min(
        dateInterval[0],
        `Укажите период в пределах ${dateInterval[0] ? getYear(dateInterval[0]) : "__"} года`
      )
      .max(
        dateInterval[1],
        `Укажите период в пределах ${dateInterval[0] ? getYear(dateInterval[0]) : "__"} года`
      )
      .typeError(thisDateIsToCorrect)
      .test("period_error", thisPeriodIsNotCorrect, (_, { parent: { since, until } }) =>
        isDateObject(since) && isDateObject(until) ? startOfDay(since) <= startOfDay(until) : true
      ),
    until: Yup.date()
      .nullable()
      .min(
        dateInterval[0],
        `Укажите период в пределах ${dateInterval[0] ? getYear(dateInterval[0]) : "__"} года`
      )
      .max(
        dateInterval[1],
        `Укажите период в пределах ${dateInterval[0] ? getYear(dateInterval[0]) : "__"} года`
      )
      .typeError(thisDateIsToCorrect)
      .test("period_error", emptyMessage, (_, { parent: { since, until } }) =>
        isDateObject(since) && isDateObject(until) ? startOfDay(since) <= startOfDay(until) : true
      ),
  });

  return {
    filtersFormName,
    filtersFormTitle,

    fieldsFilters,

    filters,
    setFilters,
    absoluteInitialValues: applicationsInRegistryAbsoluteInitialValues,

    initialValuesFilters,
    validationSchemaFilters,
  };
};

export type FiltersType = {
  department: string;
  containsRehab: BooleanType | "";
  medicalPrograms: string[];
  sanatorium: string;
  since: Date | null;
  until: Date | null;
  selectOnlyCorrected: boolean;
};
