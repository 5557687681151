import { Badge } from "@digitalatom/ui";
import { FormikValues } from "formik";
import React from "react";
import { RoomType } from "../../../api";
import { Columns } from "../../../styles/displayStyles";
import { HtmlContent, OBJType, PagePropsType, Table } from "../../../uiKit";
import { CardBase } from "../components/CardBase/CardBase";
import { TreatmentTypes } from "../components/CardBase/CardBase.styles";
import { SubBlock } from "../components/SubBlock/SubBlock";

export const RoomFullCard = <
  DATUM extends { id?: string },
  OBJ extends OBJType,
  FLDS extends FormikValues,
  VOBJ,
  FLTRS
>(
  props: PagePropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS> & PropsType
) => {
  const { room, methods } = props;

  const { name, containsRehab, vip, places, additional, rooms, photos, description } = room;

  return (
    <CardBase name={name} photos={photos} vip={vip} isVertical>
      <Columns columns={2}>
        <SubBlock
          title="Вместительность"
          content={`${rooms} комнаты ・ ${places} места ・ ${additional} дополнительных места`}
        />

        <SubBlock
          title="Вид лечения"
          content={
            <TreatmentTypes>
              <Badge text="СКЛ" design="minor" type="accent" />
              {containsRehab && <Badge text="РОМ" design="minor" type="accent" />}
            </TreatmentTypes>
          }
        />
      </Columns>

      <SubBlock title="Описание" content={<HtmlContent html={description} />} />
      <SubBlock title="Стоимость" content={<Table methods={methods} />} />
    </CardBase>
  );
};

type PropsType = {
  room: RoomType;
};
