import React, { FC } from "react";
import { BigOtherType, TreatmentMethodType } from "../../../api";
import { FeatureList, FeatureListItem, FeatureLists } from "../RoomsSanatoriumCard.styles";

export const SanatoriumFeatureList: FC<SanatoriumFeatureBasePropsType> = (props) => {
  const { treatments, profiles } = props;

  return (
    <FeatureLists>
      {[profiles ?? [], treatments ?? []].map(
        (features, i) =>
          !!features.length && (
            <FeatureList key={i}>
              {features.map(({ id, name }) => (
                <FeatureListItem key={id}>{name}</FeatureListItem>
              ))}
            </FeatureList>
          )
      )}
    </FeatureLists>
  );
};

export type SanatoriumFeatureBasePropsType = {
  treatments?: TreatmentMethodType[];
  profiles?: BigOtherType[];
};
