import React, { FC } from "react";
import { CheckMark } from "../Icons";
import * as elements from "./DocumentsInput.styles";

export const InputSuccess: FC = () => {
  const { Container, Icon, Title, Description, Text, LoadBarAndText, LoadBar } = elements;

  return (
    <Container status="success" as="div">
      <LoadBarAndText status="success">
        <LoadBar status="success">
          <CheckMark />
        </LoadBar>
        <Text>
          <Title status="success">Файлы загружены</Title>
          <Description status="success">Обработка завершается...</Description>
        </Text>
      </LoadBarAndText>

      <Icon />
    </Container>
  );
};
