import { Formik } from "formik";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import {
  absoluteInitialVacationers,
  otherInitialVacationers,
  selectedRoomsState,
} from "../../../../../../atoms";
import { FormButtons } from "../../../../../../uiKit";
import { Form, Modal } from "./VacationersModal.styles";
import { useForms } from "./hooks/useForms";

export const VacationersModal: FC<PropsType> = (props) => {
  const { onClose } = props;

  const { formName, formFields, initialValue, onSubmit, validationSchema } = useForms();

  const selectedRooms = useAtomValue(selectedRoomsState);

  return (
    <Modal>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values);
          onClose();
        }}
      >
        {({ handleSubmit, setValues }) => (
          <Form id={formName} onSubmit={handleSubmit}>
            {formFields}

            <FormButtons
              formName={formName}
              onClear={() =>
                setValues(
                  selectedRooms.length ? otherInitialVacationers : absoluteInitialVacationers
                )
              }
              saveOrAdd="clear"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

type PropsType = {
  onClose: () => void;
};
