import { add } from "date-fns";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import * as api from "../../api";
import { BooleanType, EmployeesRequestType, orgEmployeesApi, SortTypeType } from "../../api";
import { employeeOption, otherOption } from "../../constants/options";
import { ApplicationFormValuesType } from "../../pages/ApplicationLifecyclePages/Applications/ApplicationForm/hooks/useForm";
import { fetchAll, fetchById } from "../../utils/fetchData";
import { formatDate, today } from "../../utils/workingWithDates";
import {
  currentUserAtom,
  pageState,
  quantityInOnePageState,
  sanatoriumIdState,
  sanatoriumsFiltersState,
  sortingTypeState,
} from "../index";

const { roomsApi } = api;

// ------------------------------ ТЕКУЩИЙ НОМЕР

export const roomIdState = atom<string | undefined>(undefined);

// ------------------------------ ВЫБРАННЫЕ НОМЕРА

export const selectedRoomsState = atom<SelectedRoomType[]>([]);

// ------------------------------ ВЫБРАННЫЕ ОТДЫХАЮЩИЕ

export const absoluteInitialVacationers = {
  containsRehab: "false" as BooleanType,
  adults: [employeeOption.id],
  children: [],
  filterText: "1 взрослый",
};

export const otherInitialVacationers = {
  containsRehab: "false" as BooleanType,
  adults: [otherOption.id],
  children: [],
  filterText: "1 взрослый",
};

export const vacationersState = atom<VacationersType>(absoluteInitialVacationers);

export type VacationersType = {
  containsRehab: BooleanType;
  adults: api.FamilyMemberTypeType[];
  children: ChildrenType;
  filterText: string;
};

export type ChildrenType = {
  age: number;
  isNotOwn: boolean;
  isSocialGroup?: boolean;
}[];

// ------------------------------ ДАННЫЕ ДЛЯ АЛЬТЕРНАТИВНОГО ВАРИАНТА

export const alternativeRoomsInfoState = atom<AlternativeRoomInfoType[] | undefined>(undefined);
export const applicationFormValuesState = atom<ApplicationFormValuesType | undefined>(undefined);
export const forApplicationAlternativeState = atom<api.ApplicationWithTaskType | undefined>(
  undefined
);

export type AlternativeRoomInfoType = VacationersType & {
  roomId: string;
  selectedRoom?: SelectedRoomType;
};

// ------------------------------ ДАННЫЕ ДЛЯ ОФОРМЛЕНИЯ НА СОТРУДНИКА

export const employeesForSelectAtom = atomWithQuery((get) => {
  const { roleModel, res } = get(currentUserAtom);

  const isUserOsr = roleModel.osr || roleModel.osrCa;

  const args: EmployeesRequestType = {
    organization: res.organization,
    mainDepartment: isUserOsr ? undefined : res.department ?? res.position?.department.code,
  };

  return {
    queryKey: ["departmentEmployeesState"],
    queryFn: () => fetchAll(() => orgEmployeesApi().getAll(args)),
    keepPreviousData: true,
    enable: roleModel.canCreateApplicationForOtherEmployee,
  };
});

// ------------------------------ ДАННЫЕ

const roomListAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const sanatoriumId = get(sanatoriumIdState);
  const filters = get(sanatoriumsFiltersState);
  const sort = get(sortingTypeState);

  const { isRehab } = filters;

  const vacationers = get(vacationersState);
  const { adults, children } = vacationers;

  const args = {
    page,
    quantity,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    sanatoriumId: sanatoriumId!,
    isRehab,
    startDate: filters.startDate ?? today,
    endDate: filters.endDate ?? add(today, { weeks: 2 }),
    adults,
    children,
    sort: (sort === "lowPrice" ? "ASC" : "DESC") as SortTypeType,
  };

  return roomListQuery(args);
});

export const roomListState = loadable(roomListAtom);

const roomListQuery = (props: PropsType) => {
  const { sanatoriumId, isRehab, startDate, endDate, adults, children, sort } = props;

  const roomFilterDto = {
    // todo: пока не реализовано на бэке
    // page: {
    //   priceSort: sort === "lowPrice" ? "asc" : "desc",
    // },

    sanatorium: { id: sanatoriumId },
    isRehab,
    sort,

    members: [
      ...adults.map((kinship) => ({ kinship: kinship === "EMPLOYEE" ? "WORKER" : kinship })),
      ...children.map((child) => ({
        kinship: "CHILD",
        isNotOwn: child.isNotOwn,
        isSocialGroup: child.isSocialGroup,
        age: child.age,
      })),
    ],

    since: formatDate({ date: startDate, type: "forBackend" }),
    until: formatDate({ date: endDate, type: "forBackend" }),
  };

  const args = { roomFilterDto };

  return {
    queryKey: [sanatoriumId, sort, isRehab, startDate, endDate, "roomsState"],
    queryFn: () => fetchById(() => roomsApi().getFiltered(args)),
    keepPreviousData: true,
    enable: !!sanatoriumId,
    refetchOnWindowFocus: false,
  };
};

type PropsType = {
  page: number;
  quantity: number;
  sanatoriumId: string;
  isRehab: string;
  startDate: Date;
  endDate: Date;
  adults: string[];
  children: ChildrenType;
  sort: SortTypeType;
};

export type SelectedRoomType = {
  roomWithAccommodation: api.RoomWithAccommodationType;
  vacationers: VacationersType;
};
