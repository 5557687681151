import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuIsExpandState } from "../../../atoms";
import { SectionType } from "../../../constants/sections";
import { Hint } from "../Hint/Hint";
import { useHint } from "../Hint/useHint";
import { openSubmenuState } from "../LeftMenu";
import { Button, Icon, Text } from "./SectionButtons.styles";

export const SectionButton: FC<PropsType> = (props) => {
  const { section } = props;

  const { rout, text, icon, not, additionalRoutes } = section;

  const menuIsExpand = useAtomValue(menuIsExpandState);
  const setOpenSubMenu = useUpdateAtom(openSubmenuState);

  const popoverHoverMethods = useHint("sectionButton", text);
  const { argsForHint } = popoverHoverMethods;

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const active =
    (pathname.includes(rout) ||
      !!additionalRoutes?.some((addRoute) => pathname.includes(addRoute))) &&
    (!not || !pathname.includes(not));

  return (
    <>
      <Button
        isExpand={menuIsExpand}
        active={active}
        onClick={() => {
          navigate(rout);
          setOpenSubMenu(undefined);
        }}
        {...argsForHint}
      >
        <Icon active={active}>{icon}</Icon>

        {menuIsExpand && (
          <Text active={active} onClick={() => navigate(rout)}>
            {text}
          </Text>
        )}
      </Button>

      <Hint type="sectionButton" popoverHoverMethods={popoverHoverMethods} />
    </>
  );
};

type PropsType = {
  section: SectionType;
};
