import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { applicationsApi, roomsApi, sanatoriumsApi } from "../../api";
import { UpdatePeriodAndPricePropsType } from "../../pages/ApplicationLifecyclePages/Applications/Application/Blocks/SanatoriumBlock/PeriodFields";
import { fetchById } from "../../utils/fetchData";
import { applicationIdState, sanatoriumIdState } from "../index";

// ------------------------------ ВАЛИДНОСТЬ ФОРМЫ

export const formIsValidState = atom<boolean>(false);

// ------------------------------ ПЕРИОД ПРОХОЖДЕНИЯ И ЦЕНА ЗАЯВКИ

export const datePeriodState = atom<
  Pick<UpdatePeriodAndPricePropsType, "since" | "until"> | undefined
>(undefined);

export const applicationPriceState = atom<number | undefined>(undefined);

// ------------------------------ ЗАЯВКА

const applicationForFormFetch = async (id: string) => {
  const application = await fetchById(() => applicationsApi().get(id ?? ""));
  // todo: точно тут надо делать запрос? Может быть получится просто обновить атом и использовать его

  try {
    application.room.photos = (await roomsApi().get(application.room.id)).data.photos ?? [];
  } catch {
    application.room.photos = [];
  }

  const separatedMembers = application.members.filter(
    (member) => member.room.id === application.room.id
  );

  try {
    const roomMembersPhotos = (
      await Promise.all(separatedMembers.map((member) => roomsApi().get(member.room.id)))
    ).map((room) => room.data.photos);

    const roomMembersPhotoMapper = Object.fromEntries(
      separatedMembers.map((room, id) => [room, roomMembersPhotos[id]])
    );

    application.members.forEach((_, id) => {
      application.members[id].room.photos =
        roomMembersPhotoMapper[application.members[id].room.id] ?? [];
    });
  } catch {
    application.members.forEach((_, id) => {
      application.members[id].room.photos = [];
    });
  }

  return application;
};

export const applicationForFormAtom = atomWithQuery((get) => {
  const applicationId = get(applicationIdState);

  const id = applicationId && applicationId !== "id" ? applicationId : undefined;

  return {
    queryKey: [applicationId, "applicationState"],
    queryFn: () => (id ? applicationForFormFetch(id) : undefined),
    enabled: !!id,
  };
});

export const applicationForFormState = loadable(applicationForFormAtom);
// todo: может быть можно использовать applicationState?

// ------------------------------ ВЫБРАННЫЙ САНАТОРИЙ

const sanatoriumForApplicationAtom = atomWithQuery((get) => {
  const id = get(sanatoriumIdState);

  return sanatoriumForApplicationQuery(id);
});

export const sanatoriumForApplicationState = loadable(sanatoriumForApplicationAtom);
// todo: может быть можно использовать sanatoriumState?

const sanatoriumForApplicationQuery = (id?: string) => ({
  queryKey: [id, "sanatoriumState"],
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  queryFn: () => fetchById(() => sanatoriumsApi().get(id!)),
  enabled: !!id,
});
