import { useField, useFormikContext } from "formik";
import React, { FC } from "react";
import { Check } from "../../../uiKit";
import { useTreatmentProfileOptions } from "../../../utils/getOptions";
import { Fields, Item, ItemText, Title } from "./TreatmentProfileFilterFields.styles";

export const TreatmentProfileFilterFields: FC<PropsType> = (props) => {
  const { name } = props;

  const treatmentProfileOptions = useTreatmentProfileOptions();

  const { setFieldValue } = useFormikContext<Record<string, string[]>>();

  const [{ value }] = useField<string[]>(name);

  const handleChange = (id: string) => {
    const filteredValues = value.filter((valueId) => valueId !== id);
    value.includes(id) ? setFieldValue(name, filteredValues) : setFieldValue(name, [...value, id]);
  };

  return (
    <Fields>
      <Title>Профиль лечения</Title>

      {treatmentProfileOptions.map(({ id, name }) => (
        <Item key={id} onClick={() => handleChange(id)}>
          <Check variant="filled" checked={value?.includes(id)} />
          <ItemText>{name}</ItemText>
        </Item>
      ))}
    </Fields>
  );
};

type PropsType = {
  name: string;
};
