import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EntityRequestBaseType, OtherWithIconType, sanatoriumServicesApi } from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { pageState, quantityInOnePageState, searchQueryState } from "../index";

export const sanatoriumServicesModalDataState = atom<OtherWithIconType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const sanatoriumServicesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return sanatoriumServicesQuery(args);
});

export const sanatoriumServicesState = loadable(sanatoriumServicesAtom);

const sanatoriumServicesQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "sanatoriumServicesState"],
    queryFn: () => fetchAll(() => sanatoriumServicesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountSanatoriumServicesAtom = atomWithQuery((get) => {
  const queryState = get(searchQueryState);

  const query = queryState || undefined;

  const args = { query };

  return totalCountSanatoriumServicesQuery(args);
});

export const totalCountSanatoriumServicesState = loadable(totalCountSanatoriumServicesAtom);

const totalCountSanatoriumServicesQuery = (props: { query?: string }) => {
  const { query } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex: 0, size: 1, filter };

  return {
    queryKey: [query, "sanatoriumServicesState"],
    queryFn: () => fetchTotalCount(() => sanatoriumServicesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const sanatoriumServicesAllState = atomWithQuery(() => ({
  queryKey: ["sanatoriumServicesAllState"],
  queryFn: () => fetchAll(() => sanatoriumServicesApi().getAll()),
}));
