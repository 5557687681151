import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { BigOtherType, EntityRequestBaseType, treatmentProfilesApi } from "../../api";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { getQueryFilter } from "../../utils/getQueryFilter";
import { pageState, quantityInOnePageState, searchQueryState } from "../index";

export const treatmentProfilesModalDataState = atom<BigOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const treatmentProfilesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return treatmentProfilesQuery(args);
});

export const treatmentProfilesState = loadable(treatmentProfilesAtom);

const treatmentProfilesQuery = (props: EntityRequestBaseType) => {
  const { startIndex, size, query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "shortName"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "treatmentProfilesState"],
    queryFn: () => fetchAll(() => treatmentProfilesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountTreatmentProfilesAtom = atomWithQuery((get) => {
  const queryState = get(searchQueryState);

  const query = queryState || undefined;

  const args = { query };

  return totalCountTreatmentProfilesQuery(args);
});

export const totalCountTreatmentProfilesState = loadable(totalCountTreatmentProfilesAtom);

const totalCountTreatmentProfilesQuery = (props: { query?: string }) => {
  const { query } = props;

  const filter = query ? [getQueryFilter(query, ["name", "shortName"])] : undefined;

  const args = { startIndex: 0, size: 1, filter };

  return {
    queryKey: [query, "totalCountTreatmentProfilesState"],
    queryFn: () => fetchTotalCount(() => treatmentProfilesApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const treatmentProfilesAllState = atomWithQuery(() => ({
  queryKey: ["treatmentProfilesAllState"],
  queryFn: () => fetchAll(() => treatmentProfilesApi().getAll()),
}));
