import styled from "@emotion/styled";
import { button, buttonTextDefault, colors, margins, size } from "../../../styles";

const { grayscaleLabel } = colors;

export const Container = styled.button`
  ${button};
  ${buttonTextDefault};
  ${size({ h: "fit" })};
  ${margins({ p: "0" })};

  svg > path {
    fill: ${grayscaleLabel};
  }
`;
