import React, { FC } from "react";
import { PanelFilter } from "../../../pages/SanatoriumListPage/FilterPanel/FilterPanel";
import { Info, Separator } from "../../../uiKit";
import * as elements from "./JustBlock.styles";

export const JustBlock: FC<PropsType> = (props) => {
  const { children, title, id, titleType = "little", titleSeparator = "wider" } = props;
  const { needFilteredPanel = false, footer, footerSeparator = "wider" } = props;
  const { isError = false, warningTitle } = props;

  const { Block, BlockBody, BlockTitle, FooterBlock, Warning } = elements;
  const { WarningTitle, WarningTitleAndDescription } = elements;

  return (
    <Block id={id}>
      {title ? (
        <>
          <BlockTitle titleType={titleType} titleSeparator={titleSeparator} isError={isError}>
            {title}
          </BlockTitle>

          {needFilteredPanel ? <PanelFilter shortVersion /> : null}

          <Separator type={titleSeparator} />
        </>
      ) : null}

      <BlockBody>
        {warningTitle ? (
          <Warning>
            <Info />
            <WarningTitleAndDescription>
              <WarningTitle>{warningTitle}</WarningTitle>
              {children}
            </WarningTitleAndDescription>
          </Warning>
        ) : (
          children
        )}
      </BlockBody>

      {footer ? (
        <>
          <Separator type={footerSeparator} />

          <FooterBlock footerSeparator={footerSeparator}>{footer}</FooterBlock>
        </>
      ) : null}
    </Block>
  );
};

type PropsType = {
  title?: JSX.Element | string;
  titleType?: "little" | "big";
  titleSeparator?: "narrow" | "wider";

  needFilteredPanel?: boolean;

  footer?: JSX.Element;
  footerSeparator?: "narrow" | "wider";

  id?: string;

  isError?: boolean;
  warningTitle?: string;
};
