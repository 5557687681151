export const fetchPlans = {
  resort_Application: { getAll: "application-rest-fetch-plan", get: "application-edit-fetch-plan" },
  resort_Sanatorium: "sanatorium-fetch-plan",
  resort_Room: "room-fetch-plan",
  resort_Region: "region-fetch-plan",
  resort_Aspect: "aspect-fetch-plan",
  resort_FamilyMember: "familyMember-fetch-plan",
  resort_VouchersFrequencyCalculationRule: "full-vouchersFrequencyCalculationRule-fetch-plan",
  resort_Registry: "registry-bp-fetch-plan",
  resort_BusinessProcessNotification: "businessProcessNotification-fetch-plan",
} as const;

type ValueOf<T> = T[keyof T];

type NestedValueOf<T> = T extends object ? ValueOf<{ [K in keyof T]: NestedValueOf<T[K]> }> : T;

export type FetchPlanType = NestedValueOf<typeof fetchPlans>;
