import { useAtomValue } from "jotai/utils";
import { MouseEvent, useState } from "react";
import { currentUserAtom } from "../../../atoms";
import { roleOptions } from "../../../constants/options";
import { preventDefault } from "../../../utils/preventDefault";
import { getFullName, getUserInitials } from "../../../utils/workingWithNames";

export const useProfile = () => {
  const { res: currentUser, roleModel } = useAtomValue(currentUserAtom);

  // ------------------------------ ДАННЫЕ ПОЛЬЗОВАТЕЛЯ

  const userIcon = currentUser.imageInBase64 ?? undefined;
  const fullName = getFullName({ nameParts: currentUser, withoutMiddleName: true });
  const initials = getUserInitials(currentUser);

  // ------------------------------ РОЛИ

  const canViewProfile = roleModel.canViewProfile;
  const canViewNotifications = roleModel.canViewNotifications;

  const { roles } = currentUser;

  const role =
    roles.length !== 0
      ? roles.map((role) => roleOptions.find(({ id }) => id === role)).filter((role) => role)[0]
          ?.name
      : "";

  // ------------------------------ ВЫЗОВ КОНТЕКСТНОГО МЕНЮ ПРОФИЛЯ ПОЛЬЗОВАТЕЛЯ

  const [profileMenu, setProfileMenuElement] = useState<HTMLElement | null>(null);

  const openProfileMenu = (evt: MouseEvent<HTMLElement>) => {
    preventDefault(evt);
    setProfileMenuElement(evt.currentTarget);
  };

  const closeProfileMenu = (evt: MouseEvent<HTMLElement>) => {
    preventDefault(evt);
    setProfileMenuElement(null);
  };

  const profileMenuIsOpen = !!profileMenu;

  return {
    fullName,
    initials,
    userIcon,

    role,
    canViewProfile,
    canViewNotifications,

    profileMenu,
    openProfileMenu,
    closeProfileMenu,
    profileMenuIsOpen,
  };
};
