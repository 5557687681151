import { DateRange } from "@mui/lab";
import { atomWithQuery } from "jotai/query";
import { loadable, useAtomValue } from "jotai/utils";
import { applicationsApi, registriesApi } from "../../api";
import {
  applicationsInRegistryFiltersState,
  FiltersType,
} from "../../pages/ApplicationLifecyclePages/Registries/Registry/hooks/useFilters";
import { fetchAll, fetchTotalCount } from "../../utils/fetchData";
import { formatDate } from "../../utils/workingWithDates";
import {
  currentDateInterval,
  pageState,
  quantityInOnePageState,
  registryIdState,
  searchQueryState,
} from "../index";

// ------------------------------ ЗАЯВКИ В РЕЕСТРЕ

const registryApplicationsAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);
  const registry = get(registryIdState);
  const filters = get(applicationsInRegistryFiltersState);
  const dateInterval = get(currentDateInterval);

  const args = { page, quantity, queryState, registry, filters, dateInterval };

  return registryApplicationsQuery(args);
});

export const registryApplicationsState = loadable(registryApplicationsAtom);

const registryApplicationsQuery = (props: PropsType) => {
  const { registry } = props;

  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "registryApplicationsState"],
    queryFn: () => fetchAll(() => applicationsApi().getFiltered(args)),
    enabled: !!registry && registry !== "",
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

const totalCountRegistryApplicationsAtom = atomWithQuery((get) => {
  const page = 0;
  const quantity = 1;
  const queryState = get(searchQueryState);
  const registry = get(registryIdState);
  const filters = get(applicationsInRegistryFiltersState);
  const dateInterval = get(currentDateInterval);

  const args = { page, quantity, queryState, registry, filters, dateInterval };

  return totalCountApplicationsQuery(args);
});

export const totalCountRegistryApplicationsState = loadable(totalCountRegistryApplicationsAtom);

const totalCountApplicationsQuery = (props: PropsType) => {
  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "totalCountRegistryApplicationsState"],
    queryFn: () => fetchTotalCount(() => applicationsApi().getFiltered(args)),
    enabled: !!props.registry && props.registry !== "",
    keepPreviousData: true,
  };
};

// ------------------------------ ВСЕ ЗАЯВКИ

const registryAllApplicationsAtom = atomWithQuery((get) => {
  const page = 0;
  const quantity = 1;
  const registry = get(registryIdState);
  const filters = get(applicationsInRegistryFiltersState);
  const dateInterval = get(currentDateInterval);

  const args = { page, quantity, registry, filters, dateInterval };

  return registryAllApplicationsQuery(args);
});

export const registryAllApplicationsState = loadable(registryAllApplicationsAtom);

const registryAllApplicationsQuery = (props: PropsType) => {
  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "registryAllApplicationsState"],
    queryFn: () => fetchAll(() => applicationsApi().getAllFiltered(args)),
    enabled: !!props.registry && props.registry !== "",
    keepPreviousData: true,
  };
};

// ------------------------------ ВЫЧИСЛЕНИЕ АРГУМЕНТОВ

export const useRegistryApplicationsKeys = () => {
  const page = useAtomValue(pageState);
  const quantity = useAtomValue(quantityInOnePageState);
  const queryState = useAtomValue(searchQueryState);
  const registry = useAtomValue(registryIdState);
  const filters = useAtomValue(applicationsInRegistryFiltersState);
  const dateInterval = useAtomValue(currentDateInterval);

  const args = { page, quantity, queryState, registry, filters, dateInterval };

  return getArgs(args).keys;
};

const getArgs = (props: PropsType) => {
  const { page, quantity, queryState, registry, filters, dateInterval } = props;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState;

  const sanatoriumId = filters.sanatorium || undefined;
  const department = filters.department || undefined;
  const containsRehab = filters.containsRehab || undefined;
  const medicalProgramIds =
    filters.medicalPrograms.length && containsRehab === "true"
      ? filters.medicalPrograms.map((uuid) => ({ uuid }))
      : undefined;
  const corrected = filters.selectOnlyCorrected || undefined;
  const since = filters.since
    ? formatDate({ date: filters.since, type: "forBackend" })
    : dateInterval[0]
    ? formatDate({ date: dateInterval[0], type: "forBackend" })
    : undefined;
  const until = filters.until
    ? formatDate({ date: filters.until, type: "forBackend" })
    : dateInterval[1]
    ? formatDate({ date: dateInterval[1], type: "forBackend" })
    : undefined;

  const args = {
    startIndex,
    size,
    registry,
    filter: {
      query,
      sanatoriumId,
      department,
      containsRehab,
      medicalProgramIds,
      since,
      until,
      corrected,
    },
  };

  const keys = [
    page,
    quantity,
    queryState,
    registry,
    JSON.stringify(filters),
    JSON.stringify(dateInterval),
  ];

  return { keys, args };
};

type PropsType = {
  page: number;
  quantity: number;
  queryState?: string;
  registry: string;
  filters: FiltersType;
  dateInterval: DateRange<Date>;
};

// ------------------------------ ТЕКУЩИЙ РЕЕСТР

const registryAtom = atomWithQuery((get) => {
  const registryId = get(registryIdState);

  return registryQuery(registryId);
});

export const registryState = loadable(registryAtom);

const registryQuery = (registryId: string) => ({
  queryKey: [registryId, "registryAtom"],
  queryFn: () => registriesApi().getRegistryWithTask(registryId),
  // todo: по лучится ли переписать через fetchById?
  enabled: !!registryId,
});
